import { CharityDto } from '@expo/api-client';
import { AgreementInfo as AgreementInfoT } from '../../home/common/types/AgreementInfo';
import { defaultTheme } from '@expo/ui/src/core/theme';
import { Margin } from '@expo/ui/src/layout';
import { H3, LightP, H4, P } from '@expo/ui/src/typography';

type Props = {
  agreementInfo: Omit<AgreementInfoT, 'emailVerification'>;
  chosenCharity: CharityDto | undefined;
};

export const AgreementInfo = ({ agreementInfo, chosenCharity }: Props) => {
  const formattedAmount = agreementInfo.amount.replace(/\D/g, '');

  return (
    <>
      <Margin $top="7xl" />
      <H3>Autogiroanmnälan</H3>
      <LightP>
        Jag vill skänka{' '}
        <span style={{ color: chosenCharity?.bgColorHex ?? defaultTheme.colors.red }}>{formattedAmount}kr</span> i
        månaden.
      </LightP>

      <Margin $top="m" />
      <H4>Betalare</H4>

      <Margin $top="s" />
      <P>Fullständigt namn:</P>
      <Margin $top="xs" />
      <LightP>
        {agreementInfo.firstName} {agreementInfo.lastName}
      </LightP>

      <Margin $top="s" />
      <P>Personnummer:</P>
      <Margin $top="xs" />
      <LightP>{agreementInfo.ssid}</LightP>

      <Margin $top="s" />
      <P>Konto (clearingnummer + bankkonto):</P>
      <Margin $top="xs" />
      <LightP>{agreementInfo.bankAccount}</LightP>

      <Margin $top="s" />
      <P>Mottagare:</P>
      <Margin $top="xs" />
      <LightP dangerouslySetInnerHTML={{ __html: chosenCharity?.address.replace(/\n/g, '<br>') ?? '' }} />

      <Margin $top="s" />
      <LightP>{chosenCharity?.termsAndConditions}</LightP>
    </>
  );
};
