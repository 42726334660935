import { Outlet } from 'react-router-dom';
import { AuthenticationCheck } from '../../features/volunteer/authenticationCheck/AuthenticationCheck';
import { ChosenCharityProvider } from '../context/chosenCharityProvider';
import { CharityCheck } from '../../features/volunteer/charityCheck/CharityCheck';
import { Footer } from './Footer';
import { Header } from './Header';
import AuthProvider from '../auth/provider';
import { Main } from '@expo/ui/src/layout';

export const Layout: React.FC = () => {
  return (
    <AuthProvider>
      <AuthenticationCheck>
        <ChosenCharityProvider>
          <CharityCheck>
            <Main>
              <Header />
              <Outlet />
              <Footer />
            </Main>
          </CharityCheck>
        </ChosenCharityProvider>
      </AuthenticationCheck>
    </AuthProvider>
  );
};
