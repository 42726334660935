import { defaultTheme } from '../core/theme';
import styled from 'styled-components';

export type PaddingProp = {
  $top?: keyof (typeof defaultTheme)['spacing'];
  $bottom?: keyof (typeof defaultTheme)['spacing'];
  $left?: keyof (typeof defaultTheme)['spacing'];
  $right?: keyof (typeof defaultTheme)['spacing'];
};

export const Padding = styled.div<PaddingProp>`
  width: 100%;
  padding-top: ${({ $top }: PaddingProp) => defaultTheme.spacing[$top ?? 'none']};
  padding-bottom: ${({ $bottom }: PaddingProp) => defaultTheme.spacing[$bottom ?? 'none']};
  padding-right: ${({ $right }: PaddingProp) => defaultTheme.spacing[$right ?? 'none']};
  padding-left: ${({ $left }: PaddingProp) => defaultTheme.spacing[$left ?? 'none']};
`;
