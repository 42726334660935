import React from 'react';
import styled from 'styled-components';

const HamburgerMenuWrapper = styled.div`
  width: 1.75rem;
  height: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

const HamburgerMenuItem = styled.div`
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.1s ease-out;
`;

const CrossedMenuItem = styled(HamburgerMenuItem)`
  transition: all 0.1s ease-out;

  &.open {
    position: absolute;
    top: 50%;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
    &:nth-child(3) {
      opacity: 0;
    }
  }
`;

type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const HamburgerMenuButton = ({ isOpen, setIsOpen }: Props) => {
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HamburgerMenuWrapper onClick={handleClick}>
      <CrossedMenuItem className={isOpen ? 'open' : ''} />
      <CrossedMenuItem className={isOpen ? 'open' : ''} />
      <CrossedMenuItem className={isOpen ? 'open' : ''} />
    </HamburgerMenuWrapper>
  );
};
