import { format } from 'date-fns';
import { validateControlQuestions } from './Validate';
import React, { ReactElement, useState } from 'react';
import { useChosenCharity } from '../../../../traits/context/chosenCharity';
import { useNavigate, useParams } from 'react-router-dom';
import { useChosenPublicCharity } from '../../../../traits/context/chosenPublicCharity';
import { MainButton, SecondaryButton } from '@expo/ui/src/buttons';
import { Input, Checkbox } from '@expo/ui/src/inputs';
import { Margin } from '@expo/ui/src/layout';
import { H4, ErrorText } from '@expo/ui/src/typography';
import { mandatoryControlQuestions } from './mandatoryControlQuestions';
import { GetMyCharityResponse } from '@expo/api-client';
import { defaultControlQuestions } from './defaultControlQuestions';
import { AgreementInfo } from '../../home/common/types/AgreementInfo';

export type ControlQuestion = {
  checked: boolean;
  label: string | ReactElement;
  id: number;
};

export const SignAgreementForm = ({
  onNext,
  agreementInfo,
}: {
  onNext: () => void;
  agreementInfo: Omit<AgreementInfo, 'emailVerification'>;
}) => {
  const navigate = useNavigate();
  const now = new Date();

  const { chosenCharity } = useChosenCharity();
  const { chosenCharity: chosenPublicCharity } = useChosenPublicCharity();
  const { slug } = useParams();

  const [error, setError] = useState<string | null>(null);
  const [date, setDate] = useState(format(now, 'yyyy-MM-dd'));
  const [controlQuestions, setControlQuestions] = useState(getControlQuestions(chosenCharity ?? chosenPublicCharity));

  const datePlaceholder = format(now, 'yyyy/MM/dd');

  const onSubmit = () => {
    const { valid, errors } = validateControlQuestions({
      controlQuestions,
      date,
    });
    if (!valid) return setError(errors[0]);

    onNext();
  };

  return (
    <>
      <Margin $top="m" />
      <H4>Datum</H4>
      <Margin $top="s" />
      <Input
        type="date"
        charityStyles={chosenCharity ?? chosenPublicCharity}
        value={date}
        onChangeText={setDate}
        placeholder={datePlaceholder}
      />

      <Margin $top="m" />
      <H4>Kontrollfrågor</H4>
      <Margin $top="s" />
      {controlQuestions.map(question => (
        <React.Fragment key={question.id}>
          <Margin $top="m" />
          <Checkbox
            checked={question.checked}
            onValueChange={checked => {
              const newQuestions = controlQuestions.map(q => {
                if (q.id !== question.id) {
                  return q;
                }
                return { ...q, checked };
              });

              setControlQuestions(newQuestions);
            }}
            label={question.label}
          />
        </React.Fragment>
      ))}

      <Margin $top="xl" />
      {error ? (
        <>
          <ErrorText>{error}</ErrorText>
          <Margin $top="s" />
        </>
      ) : (
        <></>
      )}
      <MainButton charityStyles={chosenCharity ?? chosenPublicCharity} onClick={onSubmit}>
        Gå vidare till signering
      </MainButton>

      <Margin $top="m" />
      <SecondaryButton
        onClick={() => {
          navigate(`/${slug}?saleId=${agreementInfo.saleId}`);
          window.location.reload();
        }}
      >
        Tillbaka
      </SecondaryButton>
    </>
  );
};

function getControlQuestions(chosenCharity: GetMyCharityResponse | undefined) {
  const customControlQuestions = chosenCharity?.controlQuestions;
  const parsedCustomerControlQuestions: ControlQuestion[] =
    customControlQuestions?.map(({ question }, id) => ({
      checked: false,
      label: question,
      expectsCheck: true,
      id,
    })) ?? [];
  const hasCustomQuestions = parsedCustomerControlQuestions.length > 0;

  return [
    ...(hasCustomQuestions ? parsedCustomerControlQuestions : defaultControlQuestions),
    ...mandatoryControlQuestions,
  ];
}
