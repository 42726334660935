import { ControlQuestion } from './SignAgreementForm';

export const defaultControlQuestions: ControlQuestion[] = [
  {
    checked: false,
    label: 'Visade säljaren sitt ID?',
    id: 0,
  },
  {
    checked: false,
    label: 'Jag har förstått att detta är ett månatligt givande',
    id: 1,
  },
] as const;
