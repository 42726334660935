import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AgreementInfo as AgreementInfoT } from '../../home/common/types/AgreementInfo';
import { Signature } from './Signature';
import { useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { AgreementInfo } from './AgreementInfo';
import { useApi } from '../../../../traits/hooks/useApi';
import { SignAgreementForm } from './SignAgreementForm';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { SecondaryButton, MainButton } from '@expo/ui/src/buttons';
import { HeadingImage } from '@expo/ui/src/images';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { H2, H4, ErrorText } from '@expo/ui/src/typography';
import insamlingsKontrollSvg from '../../../../assets/insamlings_kontroll.svg';
import { PublicSaleResponse } from '@expo/api-client';

export const RegularSignAgreementPage = ({ sale }: { sale: PublicSaleResponse }) => {
  const navigate = useNavigate();
  const api = useApi();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const publicKey = queryParams.get('publicKey') ?? '';

  const { slug } = useParams();
  const chosenCharity = sale?.charity;

  const signaturePadRef = useRef<SignaturePad>(null);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const agreementInfo: Omit<AgreementInfoT, 'emailVerification'> | undefined = {
    ...sale,
    ...sale?.customer,
    publicKey,
    saleId: sale?.saleId ?? '',
    clearingNumber: sale?.clearingNumber ?? '',
    notes: sale?.notes ?? '',
    amount: sale?.amount.toString() ?? '',
    bankAccount: sale?.bankAccount ?? '',
    ssid: sale?.customer.ssid ?? '',
    email: sale?.customer.email ?? '',
    phone: sale?.customer.phone ?? '',
    firstName: sale?.customer.firstName ?? '',
    lastName: sale?.customer.lastName ?? '',
    address: sale?.customer.address ?? '',
    zipCode: sale?.customer.zipCode ?? '',
    city: sale?.customer.city ?? '',
  };

  if (!agreementInfo) {
    return (
      <MainContentWrapper>
        <H2>Resursen kunde inte laddas in.</H2>
      </MainContentWrapper>
    );
  }

  if (loading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  const onSubmit = () => {
    setLoading(true);

    setError(null);
    const signatureDataUrl = signaturePadRef.current?.toDataURL() ?? '';

    api
      .saleControllerSignSale(agreementInfo.saleId, {
        signatureDataUrl,
        publicKey: agreementInfo.publicKey,
      })
      .then(() => {
        setLoading(false);
        navigate(`/${slug}/sign-confirmation?publicKey=${publicKey}`);
      })
      .catch(e => {
        setLoading(false);
        setError(`Lyckades inte gå vidare. Felmeddelande: ${e}`);
      });
  };
  const clearSignature = () => signaturePadRef.current?.clear();

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={chosenCharity?.logo.downloadUrl} />
        <Margin $top="xl" />
        <HeadingImage width="150px" src={insamlingsKontrollSvg} />
      </Flex>

      <AgreementInfo chosenCharity={chosenCharity} agreementInfo={agreementInfo} />

      {currentStep === 0 ? <SignAgreementForm agreementInfo={agreementInfo} onNext={() => setCurrentStep(1)} /> : <></>}

      {currentStep === 1 ? (
        <>
          <Margin $top="m" />
          <H4>Din signatur</H4>
          <Margin $top="s" />
          <Signature ref={signaturePadRef} />
          <Margin $top="s" />
          <SecondaryButton type="button" onClick={clearSignature}>
            Rensa signatur
          </SecondaryButton>

          <Margin $top="xl" />
          {error ? (
            <>
              <ErrorText>{error}</ErrorText>
              <Margin $top="s" />
            </>
          ) : (
            <></>
          )}
          <MainButton charityStyles={chosenCharity} onClick={onSubmit}>
            Signera avtal
          </MainButton>

          <Margin $top="m" />
          <SecondaryButton onClick={() => setCurrentStep(0)}>Tillbaka</SecondaryButton>
        </>
      ) : (
        <></>
      )}
    </MainContentWrapper>
  );
};
