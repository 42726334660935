import React from 'react';
import { useApi } from '../hooks/useApi';
import { UserCredential } from 'firebase/auth';
import { LoginInfo } from '../auth/provider';

export enum Status {
  Authenticating = 'authenticating',
  NotAuthenticated = 'not-authenticated',
  Authenticated = 'authenticated',
}

export type AuthContext = {
  status: Status;
  isLoading: boolean;
  currentVolunteerId: string | null;
  volunteerLocation: string | null;
  error: null | string;
  login: (loginInfo: LoginInfo, api: ReturnType<typeof useApi>) => Promise<UserCredential>;
  logout: (shouldNavigate?: boolean) => Promise<void>;
  getAccessToken: () => Promise<string>;
};

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

export const AuthContext = React.createContext<AuthContext>({
  status: Status.Authenticating,
  isLoading: false,
  error: null,

  currentVolunteerId: null,
  volunteerLocation: null,
  login: stub,
  logout: stub,

  getAccessToken: stub,
});

export const useAuth = () => React.useContext(AuthContext);
