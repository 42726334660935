import { GetSparData, PlanMetadataDto, PlanMetadataDtoSponsorTypeEnum } from '@expo/api-client';
import { useChosenCharity } from '../../../../traits/context/chosenCharity';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { formSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useApi } from '../../../../traits/hooks/useApi';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { MainButton, SecondaryButton } from '@expo/ui/src/buttons';
import { Input, RadioButton, TextArea } from '@expo/ui/src/inputs';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { ErrorText, H3 } from '@expo/ui/src/typography';
import { AgreementInfo, InputValues } from '../common/types/AgreementInfo';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

type Props = {
  sparData: GetSparData;
  prefills?: InputValues;
  metadata: PlanMetadataDto;
  setMetadata: (metadata: PlanMetadataDto) => void;
};

export const CustomerInfo = ({ sparData, metadata, setMetadata, prefills }: Props) => {
  const api = useApi();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [postError, setPostError] = useState<null | string>(null);

  const { chosenCharity } = useChosenCharity();

  const onSubmit: SubmitHandler<AgreementInfo> = async data => {
    setIsLoading(true);
    try {
      await api.customerControllerUpdateCustomer(data.ssid, {
        email: data.email,
        phone: data.phone,
      });
      const formattedAmount = data.amount.replace(/\D/g, '');
      const intAmount = parseInt(formattedAmount);

      await api.saleControllerUpdateSale(sparData.saleId, {
        amount: intAmount,
        bankAccount: data.bankAccount,
        clearingNumber: data.clearingNumber,
        notes: data.notes,
        metadata,
      });
      navigate(`sign-agreement/${sparData.saleId}?publicKey=${sparData.publicKey}`);
      setIsLoading(false);
    } catch (e) {
      const error = getErrorMsg(e);
      setPostError(error);
      setIsLoading(false);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AgreementInfo>({
    resolver: zodResolver(formSchema(chosenCharity)),
    defaultValues: {
      ...sparData,
      email: prefills?.email ?? '',
      emailVerification: prefills?.email ?? '',
      phone: prefills?.phone ?? '',
      amount: prefills?.amount ?? '200kr',
      clearingNumber: prefills?.clearingNumber ?? '',
      bankAccount: prefills?.bankAccount ?? '',
      notes: prefills?.notes ?? '',
    },
  });

  const onBack = () => {
    navigate(`/${chosenCharity?.slug}`);
    window.location.reload();
  };

  if (isLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Margin $top="7xl" />
      <H3>Dina uppgifter</H3>
      <Margin $top="l" />
      <Controller
        control={control}
        name="ssid"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Personnummer*"
            placeholder="196606066060"
            maxLength={13}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Förnamn*"
            placeholder="Sven"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Efternamn*"
            placeholder="Svensson"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="address"
        render={({ field }) => (
          <Input
            {...field}
            maxLength={100}
            charityStyles={chosenCharity}
            disabled
            label="Gatuadress*"
            placeholder="Gatan 1"
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="zipCode"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Postnummer*"
            placeholder="12121"
            maxLength={5}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="city"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Ort*"
            placeholder="Stockholm"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.email?.message}
            charityStyles={chosenCharity}
            label="E-post*"
            placeholder="sven@mail.com"
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="emailVerification"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.emailVerification?.message}
            charityStyles={chosenCharity}
            label="Upprepa e-post*"
            placeholder="sven@mail.com"
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.phone?.message}
            charityStyles={chosenCharity}
            label="Mobilnummer*"
            placeholder="070-0405942"
            maxLength={25}
          />
        )}
      />

      <Margin $top="l" />
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.amount?.message}
            charityStyles={chosenCharity}
            label="Belopp*"
            placeholder={`...kr`}
            maxLength={100}
          />
        )}
      />

      <Margin $top="l" />

      <RadioButton
        value={PlanMetadataDtoSponsorTypeEnum.GirlSponsor}
        checked={metadata.sponsorType === PlanMetadataDtoSponsorTypeEnum.GirlSponsor}
        onChangeValue={sponsorType =>
          setMetadata({ ...metadata, sponsorType: sponsorType as PlanMetadataDtoSponsorTypeEnum })
        }
        charityStyles={chosenCharity}
        label="Flickafadder"
      />

      <Margin $top="l" />

      <RadioButton
        value={PlanMetadataDtoSponsorTypeEnum.DisasterSponsor}
        checked={metadata.sponsorType === PlanMetadataDtoSponsorTypeEnum.DisasterSponsor}
        onChangeValue={sponsorType =>
          setMetadata({ ...metadata, sponsorType: sponsorType as PlanMetadataDtoSponsorTypeEnum })
        }
        charityStyles={chosenCharity}
        label="Katastroffadder"
      />

      <Margin $top="l" />
      <Controller
        control={control}
        name="notes"
        render={({ field }) => (
          <TextArea {...field} charityStyles={chosenCharity} label="Övrig anteckning" placeholder="Valfri text..." />
        )}
      />
      <Margin $top="s" />
      {errors.notes && <ErrorText>{errors.notes?.message}</ErrorText>}

      <Margin $top="m" />

      <Margin $top="l" />
      {postError && <ErrorText>{postError}</ErrorText>}
      <Margin $top="l" />
      <Margin $top="l" />
      <MainButton charityStyles={chosenCharity} type="submit">
        Gå vidare
      </MainButton>
      <Margin $top="m" />
      <SecondaryButton type="button" onClick={onBack}>
        Gå tillbaka
      </SecondaryButton>
    </form>
  );
};

type ResponseData = {
  message: string;
};

function getErrorMsg(error: unknown) {
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError<ResponseData>;
    return `Error: ${axiosError.response?.data?.message}`;
  }

  return 'Something went wrong, please check that all fields are correct, or try logging out and retrying.';
}
