import { defaultTheme } from '@expo/ui/src/core/theme';
import { useChosenCharity } from '../../../../traits/context/chosenCharity';
import { MainButton, SecondaryButton } from '@expo/ui/src/buttons';
import { Input } from '@expo/ui/src/inputs';
import { Margin } from '@expo/ui/src/layout';
import { H3, P, ErrorText } from '@expo/ui/src/typography';

type Props = {
  onSubmit: () => void;
  setSsid: (ssid: string) => void;
  ssidError: string | null;
  ssid: string;
  setManualEntry: (manual: boolean) => void;
};

export const RetrieveSparData = ({ onSubmit, setSsid, ssid, ssidError, setManualEntry }: Props) => {
  const { chosenCharity } = useChosenCharity();

  return (
    <>
      <Margin $top="7xl" />
      <H3>Uppge personnummer</H3>
      <P color={defaultTheme.colors.darkerGrey}>Dina uppgifter hämtas automatiskt i nästa steg</P>
      <Margin $top="xl" />
      <Input
        charityStyles={chosenCharity}
        value={ssid}
        onChangeText={newSsid => setSsid(newSsid)}
        label="Personnummer"
        placeholder="YYYYMMDDXXXX"
        maxLength={13}
      />
      <Margin $top="l" />
      {ssidError && <ErrorText>{ssidError}</ErrorText>}
      <MainButton onClick={onSubmit} charityStyles={chosenCharity}>
        Gå vidare
      </MainButton>
      <Margin $top="m" />
      <SecondaryButton type="button" onClick={() => setManualEntry(true)}>
        Ange manuellt
      </SecondaryButton>
    </>
  );
};
