import styled from 'styled-components';
import { Flex, Margin } from '../layout';
import React, { ReactElement } from 'react';
import { defaultTheme } from '../core/theme';
import { LightP } from '../typography';

const StyledCheckbox = styled.input<{ size: Props['checkboxSizePx'] }>`
  width: ${props => props.size ?? '20px'};
  height: ${props => props.size ?? '20px'};
`;

type Props = {
  label: string | ReactElement;
  onValueChange?: (checked: boolean) => void;
  checked: boolean;
  textColor?: string;
  checkboxSizePx?: number;
};

export const Checkbox = ({ label, onValueChange, checked, textColor, checkboxSizePx }: Props) => {
  return (
    <Flex justify="flex-start" align="center" flexDir="row">
      <div>
        <StyledCheckbox
          size={checkboxSizePx}
          checked={checked}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>) =>
            onValueChange?.((target as HTMLInputElement).checked)
          }
          type="checkbox"
        />
      </div>
      <Flex flexDir="row">
        <Margin $left="s" />
        <LightP color={textColor ?? defaultTheme.colors.black}>{label}</LightP>
      </Flex>
    </Flex>
  );
};
