import { CharityDto, PlanMetadataDto } from '@expo/api-client';
import { AgreementInfo as AgreementInfoT } from '../../home/common/types/AgreementInfo';
import { defaultTheme } from '@expo/ui/src/core/theme';
import { Margin } from '@expo/ui/src/layout';
import { H3, LightP, H4, P } from '@expo/ui/src/typography';

type Props = {
  agreementInfo: Omit<AgreementInfoT, 'emailVerification'>;
  metadata: Omit<PlanMetadataDto, 'syncStatus'>;
  chosenCharity: CharityDto | undefined;
};

export const AgreementInfo = ({ agreementInfo, chosenCharity, metadata }: Props) => {
  const formattedAmount = agreementInfo.amount.replace(/\D/g, '');

  return (
    <>
      <Margin $top="2xl" />
      <H3>Månadsgåva</H3>
      <LightP>
        Jag vill bli{' '}
        <span style={{ color: chosenCharity?.bgColorHex ?? defaultTheme.colors.red }}>
          {getUserFriendlySponsorType(metadata)}
        </span>{' '}
        och ge <span style={{ color: chosenCharity?.bgColorHex ?? defaultTheme.colors.red }}>{formattedAmount}kr</span>{' '}
        per månad.
      </LightP>

      <Margin $top="m" />
      <H4>Betalare</H4>

      <Margin $top="s" />
      <P>Fullständigt namn:</P>
      <Margin $top="xs" />
      <LightP>
        {agreementInfo.firstName} {agreementInfo.lastName}
      </LightP>

      <Margin $top="s" />
      <P>Personnummer:</P>
      <Margin $top="xs" />
      <LightP>{agreementInfo.ssid}</LightP>

      <Margin $top="s" />
      <P>Mottagare:</P>
      <Margin $top="xs" />
      <LightP dangerouslySetInnerHTML={{ __html: chosenCharity?.address.replace(/\n/g, '<br>') ?? '' }} />

      <Margin $top="s" />
      <LightP>{chosenCharity?.termsAndConditions}</LightP>
    </>
  );
};

function getUserFriendlySponsorType(metadata: Pick<PlanMetadataDto, 'sponsorType'>) {
  switch (metadata.sponsorType) {
    case 'DISASTER_SPONSOR':
      return 'Katastroffadder';
    case 'GIRL_SPONSOR':
      return 'Flickafadder';
  }
}
