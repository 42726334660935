import { GetMyCharityResponse } from '@expo/api-client';
import { PlanHomeFlow } from './planFlow/PlanHomeFlow';
import { RegularHomeFlow } from './regularFlow/RegularHomeFlow';

export const RenderDynamicFlow = ({ chosenCharity }: { chosenCharity: GetMyCharityResponse }) => {
  switch (chosenCharity.flow) {
    case 'PLAN':
      return <PlanHomeFlow />;
    case 'REGULAR':
    case 'SSM':
    default:
      return <RegularHomeFlow />;
  }
};
