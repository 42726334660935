import styled from 'styled-components';
import { useAuth } from '../context/auth';
import { useState } from 'react';
import { GetMyCharityResponse } from '@expo/api-client';
import { useNavigate, useParams } from 'react-router-dom';
import { useChosenCharity } from '../context/chosenCharity';
import { defaultTheme } from '@expo/ui/src/core/theme';
import { Flex, Margin } from '@expo/ui/src/layout';
import { HamburgerMenuButton } from '@expo/ui/src/buttons';
import { LightP, P } from '@expo/ui/src/typography';

type WrapperProps = {
  isOpen: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  padding: ${defaultTheme.spacing.xl};
  background-color: ${props => (props.isOpen ? defaultTheme.colors.lightestGrey : 'none')};
  display: flex;
  flex-direction: column;

  max-height: ${props => (props.isOpen ? '200px' : '100px')};
  overflow: hidden;

  transition: all 0.05s ease-out;

  @media (max-width: 580px) {
    padding: ${defaultTheme.spacing.m};
  }
`;

const DynamicBorder = styled(Flex)<WrapperProps>`
  border-bottom: ${props => (props.isOpen ? `1px solid ${defaultTheme.colors.grey}` : 'none')};
  padding-bottom: 0.8rem;
`;

const ClickableText = styled(LightP)`
  cursor: pointer;
`;

const ClickableImage = styled.img`
  cursor: pointer;
`;

const HideIfTooSmall = styled(Flex)`
  @media (max-width: 340px) {
    display: none;
  }
`;

export const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { chosenCharity } = useChosenCharity();
  const [isOpen, setIsOpen] = useState(false);

  if (!chosenCharity) {
    return <></>;
  }

  return (
    <Wrapper isOpen={isOpen}>
      <DynamicBorder isOpen={isOpen} flexDir="row" justify="space-between">
        <Flex flexDir="row" align="center">
          {isOpen ? (
            <OpenContent onNavigate={() => setIsOpen(false)} chosenCharity={chosenCharity} />
          ) : (
            <ClosedContent chosenCharity={chosenCharity} />
          )}
        </Flex>
        <HamburgerMenuButton isOpen={isOpen} setIsOpen={setIsOpen} />
      </DynamicBorder>
      {isOpen ? (
        <>
          <Margin $top="m" />
          <ClickableText
            onClick={() => {
              setIsOpen(false);
              navigate(`/${slug}/dashboard`);
            }}
          >
            Dashboard
          </ClickableText>
          <Margin $top="m" />
          <ClickableText
            onClick={() => {
              setIsOpen(false);
              auth.logout(true);
            }}
          >
            Logga ut
          </ClickableText>
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

const ClosedContent = ({ chosenCharity }: { chosenCharity: GetMyCharityResponse | undefined }) => {
  return (
    <HideIfTooSmall flexDir="row">
      <P>Inloggad som:</P>
      <Margin $left="xs" />
      <LightP>{chosenCharity?.name}</LightP>
    </HideIfTooSmall>
  );
};

type OpenContentProps = { chosenCharity: GetMyCharityResponse | undefined; onNavigate: () => void };

const OpenContent = ({ chosenCharity, onNavigate }: OpenContentProps) => {
  const navigate = useNavigate();

  return (
    <>
      <ClickableImage
        onClick={() => {
          onNavigate();
          navigate(`/${chosenCharity?.slug}`);
        }}
        height={50}
        src={chosenCharity?.logo.downloadUrl}
      />
    </>
  );
};
