export type Theme = typeof defaultTheme;

export const defaultTheme = {
  colors: {
    darkerMainRed: '#A31308',
    mainRed: '#C8281A',
    lighterMainRed: '#E24B3F',
    white: '#ffffff',
    lightestGrey: '#EEEEEE',
    lighterGrey: '#d9d9d9',
    grey: '#CDCDCD',
    darkerGrey: '#A4A4A4',
    darkestGrey: '#747474',
    lightestRed: '#FF6464',
    lighterRed: '#F24560',
    red: '#ED1A3A',
    darkerRed: '#C30220',
    black: '#000',
    yellow: '#F3D94D',
    lightGreen: '#B3FFBB',
    green: '#218100',
    almostBlack: '#3E3E3E',
    darkerGreen: '#27AE60',
  },
  spacing: {
    none: '0',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    '2xl': '40px',
    '3xl': '48px',
    '4xl': '56px',
    '5xl': '64px',
    '6xl': '72px',
    '7xl': '80px',
    '8xl': '88px',
    '9xl': '96px',
    '10xl': '104px',
    '11xl': '112px',
    '12xl': '120px',
    '13xl': '128px',
    '14xl': '136px',
    auto: 'auto',
  },
};
