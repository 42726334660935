import { ButtonHTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import React from 'react';
import { darken, lighten } from 'polished';

type Children = ReactElement | ReactElement[] | string;
type CharityStyles = { fontColorHex?: string; bgColorHex?: string };
type ButtonProps = { notFullWidth?: boolean; charityStyles?: CharityStyles };

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: Children;
  charityStyles?: CharityStyles;
  notFullWidth?: boolean;
};

const MainButtonStyles = styled.button<ButtonProps>`
  border: none;
  outline: none;
  color: ${defaultTheme.colors.white};
  background-color: ${({ charityStyles }) => charityStyles?.bgColorHex ?? defaultTheme.colors.mainRed};
  padding: 0.8rem;
  border-radius: 4px;
  ${({ notFullWidth }) => (notFullWidth ? '' : 'width: 100%;')}

  &:hover {
    background-color: ${({ charityStyles }) => {
      return charityStyles?.bgColorHex ? darken(0.1, charityStyles?.bgColorHex) : defaultTheme.colors.darkerMainRed;
    }};
    cursor: pointer;
  }

  &:disabled {
    background-color: ${({ charityStyles }) => {
      return charityStyles?.bgColorHex ? lighten(0.1, charityStyles?.bgColorHex) : defaultTheme.colors.lighterMainRed;
    }};
    cursor: not-allowed;
  }
`;

export const MainButton = (props: Props) => {
  return <MainButtonStyles {...props}>{props.children}</MainButtonStyles>;
};
