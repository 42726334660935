import styled from 'styled-components';
import { defaultTheme } from '../core/theme';

type Props = { chosen?: boolean };

export const ClickableText = styled.a<Props>`
  all: unset;
  display: block;

  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: ${props => props.color ?? props.theme.colors.black};

  padding: ${defaultTheme.spacing.s} ${defaultTheme.spacing.m};
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background: ${defaultTheme.colors.lighterGrey};
  }

  &:focus {
    background: ${defaultTheme.colors.grey};
  }

  ${props =>
    props.chosen &&
    `
    background: ${defaultTheme.colors.grey};
    padding: ${defaultTheme.spacing.s} ${defaultTheme.spacing.m};
    border-radius: 4px;
  `}
`;
