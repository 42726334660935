import React from 'react';
import { useApi } from '../hooks/useApi';
import { UseQueryResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { GetMyCharityResponse } from '@expo/api-client';
import { useLocation, useParams } from 'react-router-dom';

export type ChosenCharityContext = {
  chosenCharity?: GetMyCharityResponse;
  query?: UseQueryResult<AxiosResponse<GetMyCharityResponse> | null>;
};

export const PublicChosenCharityContext = React.createContext<ChosenCharityContext>({});

export const PublicChosenCharityProvider: React.FC<React.PropsWithChildren> = props => {
  const { slug } = useParams();
  const location = useLocation();
  const api = useApi();

  const queryParams = new URLSearchParams(location.search);
  const publicKey = queryParams.get('publicKey') ?? '';

  const publicQuery = useQuery({
    queryKey: ['publicCharities', slug],
    queryFn: async () => {
      if (!publicKey) {
        return null;
      }

      return api.charityControllerGetPublicCharity(slug ?? '', publicKey);
    },
  });

  return (
    <PublicChosenCharityContext.Provider value={{ chosenCharity: publicQuery.data?.data, query: publicQuery }}>
      {props.children}
    </PublicChosenCharityContext.Provider>
  );
};
