import { GetMyCharityResponse } from '@expo/api-client';
import { z } from 'zod';

const defaultMinSaleAmount = 50;
const defaultMaxSaleAmount = 250;

export const formSchema = (charity: GetMyCharityResponse | undefined) =>
  z.object({
    ssid: z.string().min(1, { message: 'Personnummer får ej vara tomt.' }),
    firstName: z.string().min(1, { message: 'Namn får ej vara tomt.' }),
    lastName: z.string().min(1, { message: 'Efternamn får ej vara tomt.' }),
    address: z.string().min(1, { message: 'Adress får ej vara tomt.' }),
    zipCode: z.string().min(1, { message: 'Postnummer får ej vara tomt.' }),
    city: z.string().min(1, { message: 'Ort får ej vara tomt.' }),
    email: z
      .string()
      .min(1, { message: 'E-post får ej vara tomt.' })
      .email({ message: 'E-post är ej i korrekt format.' }),
    phone: z.string().min(1, { message: 'Mobilnummer får ej vara tomt.' }),
    amount: z
      .string()
      .transform(amount => amount.replace(/\D/g, ''))
      .refine(amount => amount.length >= 1, { message: 'Belopp måste anges.' })
      .refine(amount => parseInt(amount) >= (charity?.minSaleAmount ?? defaultMinSaleAmount), {
        message:
          `Beloppet är under det tillåtna intervallet på: ` +
          `${charity?.minSaleAmount ?? defaultMinSaleAmount}-${charity?.maxSaleAmount ?? defaultMaxSaleAmount}kr`,
      })
      .refine(amount => parseInt(amount) <= (charity?.maxSaleAmount ?? defaultMaxSaleAmount), {
        message: `Beloppet är över det tillåtna intervallet på: ${charity?.minSaleAmount}-${charity?.maxSaleAmount}kr`,
      }),
    bankAccount: z.string().min(1, { message: 'Bankkonto får ej vara tomt.' }),
    clearingNumber: z.string().min(1, { message: 'Clearingnummer får ej vara tomt.' }),
    notes: z.string(),
  });
