import { useNavigate } from 'react-router-dom';
import { useChosenCharity } from '../../../traits/context/chosenCharity';
import { useChosenPublicCharity } from '../../../traits/context/chosenPublicCharity';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { MainButton } from '@expo/ui/src/buttons';
import { HeadingImage } from '@expo/ui/src/images';
import { H3, LightP } from '@expo/ui/src/typography';
import { Status, useAuth } from '../../../traits/context/auth';

export const ThankYouPage = () => {
  const { chosenCharity } = useChosenCharity();
  const { chosenCharity: chosenPublicCharity } = useChosenPublicCharity();
  const { status } = useAuth();
  const navigate = useNavigate();
  const thankYouText = chosenCharity?.thankYouText ?? chosenPublicCharity?.thankYouText ?? '';
  const onNext = () => {
    navigate(`/${chosenCharity?.slug ?? chosenPublicCharity?.slug ?? '404'}`);
  };
  const isAuthenticated = status === Status.Authenticated;

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={chosenCharity?.logo.downloadUrl ?? chosenPublicCharity?.logo.downloadUrl} />
      </Flex>
      <Margin $top="7xl" />

      <H3>Tack för ditt stöd!</H3>
      <Margin $top="xl" />
      <LightP dangerouslySetInnerHTML={{ __html: thankYouText.replace(/\n/g, '<br>') }} />
      <Margin $top="xl" />
      {isAuthenticated ? (
        <MainButton onClick={onNext} charityStyles={chosenCharity ?? chosenPublicCharity}>
          Skapa nytt avtal
        </MainButton>
      ) : (
        <></>
      )}
    </MainContentWrapper>
  );
};
