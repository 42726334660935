import { MainCard } from '@expo/ui/src/cards';
import { defaultTheme } from '@expo/ui/src/core/theme';
import { Icon } from '@expo/ui/src/icons';
import { Flex, Margin } from '@expo/ui/src/layout';
import { H3, P, H1 } from '@expo/ui/src/typography';

type Statistics = {
  salesThisMonth: number;
  monthAverage: number;
  salesThisWeek: number;
};

type Props = { statistics: Statistics | undefined };

export const VolunteerStatistics = ({ statistics }: Props) => {
  if (!statistics) {
    return <H3>No statistics to show.</H3>;
  }

  const { salesThisMonth, monthAverage, salesThisWeek } = statistics;

  return (
    <>
      <Margin $top="m" />
      <MainCard>
        <Flex flexDir="row" align="center">
          <Icon src="Calendar" width={25} />
          <Margin $left="s" />
          <P>Antal sälj månad</P>
        </Flex>
        <Margin $top="s" />
        <H1 color={defaultTheme.colors.almostBlack}>{salesThisMonth ?? 0}</H1>
      </MainCard>

      <Margin $top="m" />
      <MainCard>
        <Flex flexDir="row" align="center">
          <Icon src="Money" width={25} />
          <Margin $left="s" />
          <P>Månadens snitt</P>
        </Flex>

        <Margin $top="s" />
        <H1 color={defaultTheme.colors.almostBlack}>{monthAverage ?? 0}</H1>
      </MainCard>

      <Margin $top="m" />
      <MainCard>
        <Flex flexDir="row" align="center">
          <Icon src="Calendar" width={25} />
          <Margin $left="s" />
          <P>Antal sälj vecka</P>
        </Flex>

        <Margin $top="s" />
        <H1 color={defaultTheme.colors.almostBlack}>{salesThisWeek ?? 0}</H1>
      </MainCard>
    </>
  );
};
