import { useState } from 'react';
import { useApi } from '../../../../traits/hooks/useApi';
import { useQuery } from 'react-query';
import { validateSsid } from '../../../validation/validateSsid';
import { useAuth } from '../../../../traits/context/auth';
import { Flex, Margin } from '@expo/ui/src/layout';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { CustomerInfo } from './CustomerInfo';
import { RetrieveSparData } from './RetrieveSparData';
import {
  FindSaleResponse,
  GetSparData,
  PlanMetadataDto,
  PlanMetadataDtoSaleTechniqueEnum,
  PlanMetadataDtoSponsorTypeEnum,
  PlanMetadataDtoSyncStatusEnum,
} from '@expo/api-client';
import { ManualSparDataForm } from '../common/ManualSparDataForm/ManualSparDataForm';
import { useSearchParams } from 'react-router-dom';
import { InputValues } from '../common/types/AgreementInfo';
import { isPlanMetadataDto } from '../../../validation/isPlanMetadataDto';

export const PlanHomeFlow = () => {
  const { currentVolunteerId, volunteerLocation } = useAuth();
  const api = useApi();
  const [metadata, setMetadata] = useState<PlanMetadataDto>({
    charityFlow: 'PLAN',
    saleTechnique: PlanMetadataDtoSaleTechniqueEnum.D2D,
    sponsorType: PlanMetadataDtoSponsorTypeEnum.GirlSponsor,
    syncStatus: PlanMetadataDtoSyncStatusEnum.Pending,
    location: volunteerLocation ?? 'Okänt',
  });
  const [ssid, setSsid] = useState('');
  const [ssidError, setSsidError] = useState<null | string>(null);
  const [searchParams] = useSearchParams();
  const [manualEntry, setManualEntry] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  const saleId = searchParams.get('saleId');

  const { isError, isLoading: isSparLoading } = useQuery(
    ['sparData', ssid],
    () => {
      setShouldFetch(false);
      setSsidError(null);
      const valid = validateSsid(ssid);
      if (!valid) {
        setSsidError('Felaktigt format på personnummer.');
        return;
      }
      const intVolunteerId = parseInt(currentVolunteerId ?? '');
      return api.sparControllerFindOne(ssid, { volunteerId: intVolunteerId });
    },
    {
      enabled: shouldFetch,
      cacheTime: 0,
      onSuccess: res => setSparData(res?.data),
    },
  );

  const { isLoading: isPrefillsLoading, data: prefillsRes } = useQuery(
    ['saleInfo', saleId],
    () => {
      if (!saleId) return;
      return api.saleControllerFindOne(saleId);
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const { sparData, metadata } = extractPrefills(res?.data);

        if (isPlanMetadataDto(metadata)) {
          setMetadata(metadata);
        }

        setSparData(sparData);
      },
    },
  );

  const isLoading = isSparLoading || isPrefillsLoading;

  const onSubmit = () => setShouldFetch(true);

  const [sparData, setSparData] = useState<undefined | GetSparData>(undefined);

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Margin $top="7xl" />
        <LoadingCircle />
      </Flex>
    );
  }

  if (sparData) {
    const { prefills } = extractPrefills(prefillsRes?.data);

    return <CustomerInfo prefills={prefills} metadata={metadata} setMetadata={setMetadata} sparData={sparData} />;
  }

  return (
    <>
      {manualEntry ? (
        <ManualSparDataForm setSparData={setSparData} />
      ) : (
        <RetrieveSparData
          metadata={metadata}
          setMetadata={setMetadata}
          setManualEntry={setManualEntry}
          ssidError={isError ? 'Ett fel uppstod' : ssidError}
          onSubmit={onSubmit}
          setSsid={setSsid}
          ssid={ssid}
        />
      )}
    </>
  );
};

function extractPrefills(sale: FindSaleResponse | undefined) {
  if (!sale) {
    return { sparData: undefined, prefills: undefined, metadata: undefined };
  }

  const prefills: InputValues = {
    email: sale.customer.email,
    emailVerification: sale.customer.email,
    phone: sale.customer.phone,
    amount: sale.amount.toString(),
    bankAccount: sale.bankAccount,
    clearingNumber: sale.clearingNumber,
    notes: sale.notes,
  };
  const sparData: GetSparData = {
    ...sale.customer,
    saleId: sale.saleId,
    publicKey: sale.publicKey,
  };
  const metadata: PlanMetadataDto | undefined = isPlanMetadataDto(sale.metadata)
    ? {
        charityFlow: sale.metadata.charityFlow,
        saleTechnique: sale.metadata.saleTechnique,
        sponsorType: sale.metadata.sponsorType,
        syncStatus: sale.metadata.syncStatus,
        location: sale.metadata.location,
      }
    : undefined;

  return { prefills, sparData, metadata };
}
