import { ButtonHTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import React from 'react';

type Children = ReactElement | ReactElement[] | string;
type Props = ButtonHTMLAttributes<HTMLButtonElement> & { children: Children };

const SecondaryButtonStyles = styled.button`
  border: 2px solid ${defaultTheme.colors.darkerGrey};
  outline: none;
  color: ${defaultTheme.colors.black};
  background-color: ${defaultTheme.colors.white};
  padding: 0.8rem;
  border-radius: 4px;
  width: 100%;

  &:hover {
    background-color: ${defaultTheme.colors.lighterGrey};
    cursor: pointer;
  }

  &:disabled {
    background-color: ${defaultTheme.colors.white};
    cursor: not-allowed;
  }
`;

export const SecondaryButton = (props: Props) => {
  return <SecondaryButtonStyles {...props}>{props.children}</SecondaryButtonStyles>;
};
