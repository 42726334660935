import { useAuth } from '../../../../traits/context/auth';
import { useApi } from '../../../../traits/hooks/useApi';
import { Footer } from '../../../../traits/layout/Footer';
import styled from 'styled-components';
import { Flex, Main, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { MainButton } from '@expo/ui/src/buttons';
import { Input } from '@expo/ui/src/inputs';
import { ErrorText, H2 } from '@expo/ui/src/typography';
import { GetCharityPrefillResponse } from '@expo/api-client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formSchema } from './schema';

const HeadingImage = styled.img`
  max-width: 200px;
`;

type LoginCredentials = {
  email: string;
  volunteerId: string;
  password: string;
  location: string;
};

export const PlanLogin = ({ prefill }: { prefill: GetCharityPrefillResponse }) => {
  const auth = useAuth();
  const api = useApi();

  const defaultLoginCredentials: LoginCredentials = {
    email: prefill.email,
    volunteerId: '',
    password: '',
    location: '',
  };

  const onLogin = async (data: LoginCredentials) => {
    const { email, password, volunteerId, location } = data;
    await auth.login({ email, password, volunteerId, volunteerLocation: location }, api);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginCredentials>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultLoginCredentials,
  });

  return (
    <Main>
      <MainContentWrapper>
        <form onSubmit={handleSubmit(onLogin)}>
          <Margin $top="xl" />
          <Flex justify="center" align="center">
            <HeadingImage src={prefill.logoUrl} />
          </Flex>
          <Margin $top="5xl" />
          <H2>Logga in på</H2>
          <Margin $top="l" />

          <Controller
            control={control}
            name="volunteerId"
            render={({ field }) => (
              <Input
                {...field}
                errorMessage={errors.volunteerId?.message}
                charityStyles={prefill}
                label="Volontär ID"
              />
            )}
          />

          <Margin $top="l" />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input
                {...field}
                errorMessage={errors.password?.message}
                charityStyles={prefill}
                label="Lösenord"
                type="password"
              />
            )}
          />

          <Margin $top="l" />
          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <Input {...field} errorMessage={errors.location?.message} charityStyles={prefill} label="Plats" />
            )}
          />

          <Margin $top="l" />
          {auth.error ? <ErrorText>{auth.error}</ErrorText> : <></>}
          <Margin $top="l" />

          <MainButton charityStyles={prefill} type="submit">
            Logga in
          </MainButton>
        </form>
      </MainContentWrapper>
      <Footer name={prefill.name} textContent={prefill.address} imageUrl={prefill.logoUrl} />
    </Main>
  );
};
