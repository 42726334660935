import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AgreementInfo as AgreementInfoT } from '../../home/common/types/AgreementInfo';
import { Signature } from './Signature';
import { useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { AgreementInfo } from './AgreementInfo';
import { useApi } from '../../../../traits/hooks/useApi';
import { SignAgreementForm } from './SignAgreementForm';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { SecondaryButton, MainButton } from '@expo/ui/src/buttons';
import { HeadingImage } from '@expo/ui/src/images';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { H2, H4, ErrorText } from '@expo/ui/src/typography';
import insamlingsKontrollSvg from '../../../../assets/insamlings_kontroll.svg';
import { CreatePaymentDtoTypeEnum, PlanMetadataDto, PublicSaleResponse } from '@expo/api-client';

export const PlanSignAgreementPage = ({ sale }: { sale: PublicSaleResponse }) => {
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const { slug, saleId } = useParams();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const queryParams = new URLSearchParams(location.search);
  const publicKey = queryParams.get('publicKey') ?? '';
  const failedPayment = queryParams.get('paymentFailed') === 'true' || !!error;

  const signaturePadRef = useRef<SignaturePad>(null);

  if (!sale) {
    return (
      <MainContentWrapper>
        <H2>Resursen kunde inte laddas in.</H2>
      </MainContentWrapper>
    );
  }

  if (loading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  const chosenCharity = sale?.charity;

  const agreementInfo: Omit<AgreementInfoT, 'emailVerification'> | undefined = {
    ...sale,
    publicKey,
    amount: sale?.amount.toString() ?? '',
    ssid: sale?.customer.ssid ?? '',
    email: sale?.customer.email ?? '',
    phone: sale?.customer.phone ?? '',
    firstName: sale?.customer.firstName ?? '',
    lastName: sale?.customer.lastName ?? '',
    address: sale?.customer.address ?? '',
    zipCode: sale?.customer.zipCode ?? '',
    city: sale?.customer.city ?? '',
  };

  const metadata: Omit<PlanMetadataDto, 'syncStatus'> = {
    charityFlow: (sale.metadata as PlanMetadataDto).charityFlow,
    saleTechnique: (sale.metadata as PlanMetadataDto).saleTechnique,
    sponsorType: (sale.metadata as PlanMetadataDto).sponsorType,
    location: (sale.metadata as PlanMetadataDto).location,
  };

  const signSale = async (signatureDataUrl: string, paymentType: CreatePaymentDtoTypeEnum) => {
    await api.saleControllerSignSale(agreementInfo.saleId, { publicKey, signatureDataUrl });
    const paymentRes = await api.paymentControllerCreatePayment({
      type: paymentType,
      saleId: agreementInfo.saleId,
      publicKey,
    });
    return paymentRes;
  };

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    const signatureDataUrl = signaturePadRef.current?.toDataURL() ?? '';

    try {
      const paymentRes = await signSale(signatureDataUrl, 'PLAN_ADYEN');
      setLoading(false);

      if (!paymentRes.data?.checkoutUrl) {
        return setError('Lyckades inte gå vidare. Felmeddelande: Saknar vidare-länk. Vänligen testa igen senare.');
      }

      navigate(`/${slug}/payment-qr-code/${saleId}?publicKey=${publicKey}`);
    } catch (e) {
      setError(`Lyckades inte gå vidare.`);
      setLoading(false);
    }
  };
  const onAvi = async () => {
    setLoading(true);
    setError(null);

    const signatureDataUrl = signaturePadRef.current?.toDataURL() ?? '';

    try {
      await signSale(signatureDataUrl, 'PLAN_AVI');
      navigate(`/${slug}/sign-confirmation?publicKey=${publicKey}`);
      setLoading(false);
    } catch (e) {
      setError(`Lyckades inte gå vidare. Felmeddelande: ${e}`);
      setLoading(false);
    }
  };

  const clearSignature = () => signaturePadRef.current?.clear();
  const onNext = () => setCurrentStep(1);

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={chosenCharity?.logo.downloadUrl} />
        <Margin $top="xl" />
        <HeadingImage width="150px" src={insamlingsKontrollSvg} />
      </Flex>
      <Margin $top="3xl" />
      {failedPayment ? <ErrorText>Betalning misslyckades, vänligen försök igen.</ErrorText> : <></>}

      <AgreementInfo chosenCharity={chosenCharity} metadata={metadata} agreementInfo={agreementInfo} />

      {currentStep === 0 ? <SignAgreementForm agreementInfo={agreementInfo} onNext={onNext} /> : <></>}

      {currentStep === 1 ? (
        <>
          <Margin $top="m" />
          <H4>Din signatur</H4>
          <Margin $top="s" />
          <Signature ref={signaturePadRef} />
          <Margin $top="s" />
          <SecondaryButton type="button" onClick={clearSignature}>
            Rensa signatur
          </SecondaryButton>

          <Margin $top="xl" />
          {error ? (
            <>
              <ErrorText>{error}</ErrorText>
              <Margin $top="s" />
            </>
          ) : (
            <></>
          )}
          <MainButton charityStyles={chosenCharity} onClick={onSubmit}>
            Gå till betalning
          </MainButton>

          {failedPayment && (
            <>
              <Margin $top="m" />
              <MainButton charityStyles={chosenCharity} onClick={onAvi}>
                Betala med AVI
              </MainButton>
            </>
          )}

          <Margin $top="m" />
          <SecondaryButton onClick={() => setCurrentStep(0)}>Tillbaka</SecondaryButton>
        </>
      ) : (
        <></>
      )}
    </MainContentWrapper>
  );
};
