import { SecondaryButton, MainButton } from '@expo/ui/src/buttons';
import { HeadingImage } from '@expo/ui/src/images';
import { MainContentWrapper, Flex, Margin } from '@expo/ui/src/layout';
import { ErrorText, H4 } from '@expo/ui/src/typography';
import { useChosenCharity } from '../../../../traits/context/chosenCharity';
import { useChosenPublicCharity } from '../../../../traits/context/chosenPublicCharity';
import insamlingsKontrollSvg from '../../../../assets/insamlings_kontroll.svg';
import QRCode from 'qrcode.react';
import { useApi } from '../../../../traits/hooks/useApi';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isPlanMetadataDto } from '../../../validation/isPlanMetadataDto';
import { PaymentPickStatusStatusEnum, PlanMetadataDto } from '@expo/api-client';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';

export const PaymentCheckoutQrCode = () => {
  const api = useApi();
  const navigate = useNavigate();

  const { saleId } = useParams();
  const location = useLocation();
  const { chosenCharity } = useChosenCharity();
  const { chosenCharity: chosenPublicCharity } = useChosenPublicCharity();

  const queryParams = new URLSearchParams(location.search);
  const publicKey = queryParams.get('publicKey') ?? '';

  const { data: saleRes, isLoading } = useQuery({
    queryFn: () => api.saleControllerFindPublicSale(saleId ?? '', publicKey),
    queryKey: ['publicSaleQrCodeView', saleId],
    refetchInterval: 10_000,
  });
  const sale = saleRes?.data;
  const isDone = sale?.payment.some(({ status }) => status === PaymentPickStatusStatusEnum.Done);

  if (isLoading) {
    return (
      <MainContentWrapper>
        <Flex justify="center" align="center">
          <HeadingImage src={(chosenCharity ?? chosenPublicCharity)?.logo.downloadUrl} />
          <Margin $top="xl" />
          <HeadingImage width="150px" src={insamlingsKontrollSvg} />
        </Flex>
        <Margin $top="3xl" />
        <Flex justify="center" align="center">
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  if (!sale) {
    return (
      <MainContentWrapper>
        <Flex justify="center" align="center">
          <HeadingImage src={(chosenCharity ?? chosenPublicCharity)?.logo.downloadUrl} />
          <Margin $top="xl" />
          <HeadingImage width="150px" src={insamlingsKontrollSvg} />
        </Flex>
        <Margin $top="3xl" />
        <Flex justify="center" align="center">
          <ErrorText>Sale could not be found.</ErrorText>
        </Flex>
      </MainContentWrapper>
    );
  }

  if (isDone) {
    navigate(`/${sale.charity.slug}/sign-confirmation?publicKey=${publicKey}`);
  }

  if (!isPlanMetadataDto(sale?.metadata)) {
    return (
      <MainContentWrapper>
        <Flex justify="center" align="center">
          <HeadingImage src={(chosenCharity ?? chosenPublicCharity)?.logo.downloadUrl} />
          <Margin $top="xl" />
          <HeadingImage width="150px" src={insamlingsKontrollSvg} />
        </Flex>
        <Margin $top="3xl" />
        <Flex justify="center" align="center">
          <ErrorText>
            Metadata structure seems to be incorrect for the sale. Something has gone wrong. Please retry with another
            session.
          </ErrorText>
        </Flex>
      </MainContentWrapper>
    );
  }

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={(chosenCharity ?? chosenPublicCharity)?.logo.downloadUrl} />
        <Margin $top="xl" />
        <HeadingImage width="150px" src={insamlingsKontrollSvg} />
      </Flex>
      <Margin $top="3xl" />

      <>
        <Margin $top="m" />
        <H4>Scanna QR-koden för att gå till betalning</H4>

        <Margin $top="xl" />
        <Flex justify="center" align="center">
          <QRCode size={200} value={sale?.metadata.paymentCheckoutUrl ?? ''} />
        </Flex>
        <Margin $top="xl" />
        <MainButton
          charityStyles={chosenCharity ?? chosenPublicCharity}
          onClick={() => {
            window.location.href = (sale.metadata as PlanMetadataDto).paymentCheckoutUrl ?? '';
          }}
        >
          Betala på denna enhet
        </MainButton>
        <Margin $top="m" />
        <SecondaryButton
          onClick={() =>
            navigate(`/${sale.charity.slug}/sign-agreement/${saleId}?publicKey=${publicKey}&paymentFailed=true`)
          }
        >
          Avbryt
        </SecondaryButton>
      </>
    </MainContentWrapper>
  );
};
