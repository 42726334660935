import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import { Flex, Margin } from '../layout';
import React from 'react';
import { ErrorText } from '../typography';

type CharityStyles = { fontColorHex?: string; bgColorHex?: string };

const Wrapper = styled.div`
  width: 100%;
`;

const StyledInput = styled.input<{ charityStyles?: CharityStyles }>`
  border: 2px solid ${defaultTheme.colors.grey};
  border-radius: 4px;
  outline: none;
  padding: 0.5rem;
  font-family: 'Inter';

  &:focus {
    border: 2px solid ${({ charityStyles }) => charityStyles?.bgColorHex ?? defaultTheme.colors.mainRed};
    outline: none;
  }

  &::placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }

  &:-ms-input-placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }
`;

const Label = styled.label`
  font-weight: 500;
`;

type ExtraInputProps = {
  label: string;
  onChangeValue?: (text: string) => void;
  charityStyles?: CharityStyles;
  errorMessage?: string;
};
type Props = InputHTMLAttributes<HTMLInputElement> & ExtraInputProps;

export const RadioButton = (props: Props) => {
  return (
    <Wrapper>
      <Flex flexDir="row" align="center">
        <StyledInput
          {...props}
          type="radio"
          id={`${props.label}-${props.value}`}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            props?.onChange?.(ev);
            props?.onChangeValue?.(ev.target.value);
          }}
        />
        <Margin $right="s" />
        <Label htmlFor={`${props.label}-${props.value}`}>{props.label}</Label>
      </Flex>
      {props.errorMessage && (
        <>
          <Margin $top="s" />
          <ErrorText>{props.errorMessage}</ErrorText>
        </>
      )}
    </Wrapper>
  );
};
