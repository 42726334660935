import { ReactElement } from 'react';
import { useAuth } from '../../../traits/context/auth';
import { useParams } from 'react-router-dom';
import { useChosenCharity } from '../../../traits/context/chosenCharity';
import { Flex, Main, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';

type Props = {
  children: ReactElement | ReactElement[];
};

export const CharityCheck = ({ children }: Props) => {
  const auth = useAuth();
  const { chosenCharity } = useChosenCharity();
  const { slug } = useParams();

  if (auth.status === 'not-authenticated') {
    console.error(
      `The "CharityCheck" component seems to be rendered when the user is not authorized. 
      This might cause trouble further down and should be avoided. 
      If possible, wrap it in the "AuthenticationCheck" component to eliminate this issue.`,
    );
  }

  if (auth.status !== 'authenticated' || !chosenCharity) {
    return (
      <Main>
        <MainContentWrapper>
          <Flex height="100vh" width="100%" justify="center" align="center">
            <Margin $top="7xl" />
            <LoadingCircle />
          </Flex>
        </MainContentWrapper>
      </Main>
    );
  }

  if (chosenCharity.slug !== slug) {
    auth.logout(true);
  }

  if (chosenCharity.deactivatedAt) {
    auth.logout(true);
  }

  return <>{children}</>;
};
