import { useQuery } from 'react-query';
import { useChosenCharity } from '../../../traits/context/chosenCharity';
import { useApi } from '../../../traits/hooks/useApi';
import { LeaderBoard } from './LeaderBoard';
import { VolunteerStatistics } from './VolunteerStatistics';
import { useAuth } from '../../../traits/context/auth';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { HeadingImage } from '@expo/ui/src/images';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { H3 } from '@expo/ui/src/typography';

export const DashboardPage = () => {
  const api = useApi();
  const { chosenCharity } = useChosenCharity();
  const { currentVolunteerId } = useAuth();

  const {
    data: volunteerStatisticsRes,
    error: volunteerError,
    isError: isVolunteerError,
    isLoading: isVolunteerLoading,
  } = useQuery({
    queryFn: () => api.volunteerControllerGetMyStatistics(currentVolunteerId ?? ''),
    queryKey: ['volunteer', 'me', 'statistics'],
  });
  const volunteerStatistics = volunteerStatisticsRes?.data;
  const {
    data: charityStatisticsRes,
    error: charityError,
    isError: isCharityError,
    isLoading: isCharityLoading,
  } = useQuery({
    queryFn: () => api.charityControllerGetStatistics(),
    queryKey: ['charity', 'me', 'statistics'],
  });

  const charityStatistics = charityStatisticsRes?.data;

  if (isVolunteerError || isCharityError) {
    return (
      <MainContentWrapper>
        <>{volunteerError ?? charityError}</>
      </MainContentWrapper>
    );
  }

  if (isVolunteerLoading || isCharityLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={chosenCharity?.logo.downloadUrl} />
      </Flex>

      <Margin $top="5xl" />
      <H3>Dashboard</H3>

      <VolunteerStatistics statistics={volunteerStatistics} />

      <Margin $top="xl" />
      <LeaderBoard
        title="Leaderboard städer"
        items={
          charityStatistics?.cities.map(({ city, amountOfSales }) => ({
            displayName: city,
            comparisonValue: amountOfSales,
          })) ?? []
        }
      />

      <Margin $top="l" />

      <LeaderBoard
        title="Leaderboard"
        items={
          charityStatistics?.volunteers.map(({ volunteer, amountOfSales }) => ({
            displayName: volunteer,
            comparisonValue: amountOfSales,
          })) ?? []
        }
      />
    </MainContentWrapper>
  );
};
