import React from 'react';
import { IconType } from './IconType';
import styled from 'styled-components';

type Icon = {
  src: keyof typeof IconType;
  width?: string | number;
  height?: string | number;
  onClick?: () => void;
};

const Img = styled.img<{ clickable?: boolean }>`
  ${props => (props.clickable ? 'cursor: pointer;' : '')}
`;

export const Icon: React.FC<Icon> = props => {
  const icon = IconType[props.src];

  return (
    <Img clickable={!!props.onClick} onClick={props.onClick} src={icon} width={props.width} height={props.height} />
  );
};
