import { useChosenCharity } from '../../../traits/context/chosenCharity';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { HeadingImage } from '@expo/ui/src/images';
import { RenderDynamicFlow } from './RenderDynamicFlow';
import insamlingsKontrollSvg from '../../../assets/insamlings_kontroll.svg';
import { ErrorText } from '@expo/ui/src/typography';

export const Home: React.FC = () => {
  const { chosenCharity } = useChosenCharity();

  if (!chosenCharity) {
    return <ErrorText>Expected a chosen charity but got none. Please try logging out and trying again.</ErrorText>;
  }

  return (
    <MainContentWrapper>
      <Flex justify="center" align="center">
        <HeadingImage src={chosenCharity?.logo.downloadUrl} />
        <Margin $top="xl" />
        <HeadingImage width="150px" src={insamlingsKontrollSvg} />
      </Flex>
      <RenderDynamicFlow chosenCharity={chosenCharity} />
    </MainContentWrapper>
  );
};
