import { PlanMetadataDto } from '@expo/api-client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPlanMetadataDto(obj: any): obj is PlanMetadataDto {
  if (typeof obj !== 'object') {
    return false;
  }

  if (!obj) {
    return false;
  }

  return 'charityFlow' in obj && 'saleTechnique' in obj && 'sponsorType' in obj && 'syncStatus' in obj;
}
