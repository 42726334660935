import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// FOR VOLUNTEERS
const firebaseConfig = {
  apiKey: 'AIzaSyCiqkR_54UHUCA1mS6SmzCqoy4a_v6Gw_E',
  authDomain: 'expo-v2-415609.firebaseapp.com',
  projectId: 'expo-v2-415609',
  storageBucket: 'expo-v2-415609.appspot.com',
  messagingSenderId: '43304953330',
  appId: '1:43304953330:web:8b13aaa542d79a2b1f376b',
};

// FOR ADMIN:
// const firebaseConfig = {
//   apiKey: "AIzaSyCiqkR_54UHUCA1mS6SmzCqoy4a_v6Gw_E",
//   authDomain: "expo-v2-415609.firebaseapp.com",
//   projectId: "expo-v2-415609",
//   storageBucket: "expo-v2-415609.appspot.com",
//   messagingSenderId: "43304953330",
//   appId: "1:43304953330:web:2246b8d3af419d441f376b"
// };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
