import React, { ReactElement, useEffect, useState } from 'react';
import { AuthContext, Status } from '../context/auth';
import authContextReducer from './authContextReducer';
import { auth } from '../../config/firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useApi } from '../hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';

type Props = { children: ReactElement };

export type LoginInfo = {
  email: string;
  password: string;
  volunteerId: string;
  volunteerLocation?: string;
};

export default function AuthProvider({ children }: Props) {
  const { currentUser } = auth;
  const navigate = useNavigate();
  const { slug } = useParams();
  const localStorageVolunteer = localStorage.getItem('volunteerId');
  const localStorageVolunteerLocation = localStorage.getItem('volunteerLocation') ?? null;
  const [currentVolunteerId, setCurrentVolunteerId] = useState(localStorageVolunteer);
  const [volunteerLocation, setVolunteerLocation] = useState(localStorageVolunteerLocation);

  const [state, dispatch] = React.useReducer(authContextReducer, {
    status: Status.Authenticating,
    isLoading: false,
    error: null,
  });

  const getAccessToken = async () => {
    const token = await currentUser?.getIdToken(true);

    if (!token) {
      throw new Error('Token not found');
    }
    return token;
  };

  const login = async (loginInfo: LoginInfo, api: ReturnType<typeof useApi>) => {
    try {
      dispatch({ type: 'LOGIN' });
      const { data } = await api.charityControllerValidVolunteerId(loginInfo.volunteerId).catch(() => {
        throw new Error('Misslyckad inloggning. Verifiera att volontär ID har angetts.');
      });

      if (!data.isValid) {
        const errMsg = `Volontär med volontär ID: "${loginInfo.volunteerId}" är inte giltig.`;
        throw new Error(errMsg);
      }

      const userCredentials = await signInWithEmailAndPassword(auth, loginInfo.email, loginInfo.password);
      localStorage.setItem('volunteerId', loginInfo.volunteerId);
      setCurrentVolunteerId(loginInfo.volunteerId);

      if (loginInfo.volunteerLocation) {
        localStorage.setItem('volunteerLocation', loginInfo.volunteerLocation);
        setVolunteerLocation(loginInfo.volunteerLocation);
      }

      dispatch({ type: 'LOGIN_SUCCESS' });
      return userCredentials;
    } catch (e) {
      dispatch({
        type: 'LOGIN_FAILED',
        error: getErrorMsg(e),
      });
      throw e;
    }
  };

  const logout = async (shouldNavigate = true) => {
    localStorage.removeItem('volunteerId');
    localStorage.removeItem('volunteerLocation');
    await signOut(auth);
    dispatch({ type: 'LOGOUT' });
    if (shouldNavigate) {
      navigate(`/${slug}`);
      window.location.reload();
    }
    return;
  };

  useEffect(() => {
    dispatch({ type: 'LOGIN' });
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        dispatch({ type: 'LOGIN_SUCCESS' });
      } else {
        dispatch({
          type: 'LOGIN_FAILED',
          error: null,
        });
      }
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading: state.isLoading,
        status: state.status,
        currentVolunteerId,
        volunteerLocation,
        getAccessToken,
        login,
        logout,
        error: state.error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function getErrorMsg(error: unknown): string {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof FirebaseError) {
    const firebaseError = error as FirebaseError;

    if (firebaseError.code === 'auth/missing-password') {
      return `Misslyckad inloggning. Lösenord saknas.`;
    }

    if (firebaseError.code === 'auth/invalid-credential') {
      return `Misslyckad inloggning. Lösenord är inkorrekt.`;
    }

    return `Misslyckad inloggning. Fel: ${firebaseError.message}`;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Misslyckad inloggning. Oväntat fel uppstod, vänligen försök igen senare eller kontakta support.';
}
