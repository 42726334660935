import { GetCharityPrefillResponse } from '@expo/api-client';
import { PlanLogin } from './planFlow/PlanLogin';
import { RegularLogin } from './regularFlow/RegularLogin';

export const RenderDynamicFlow = ({ prefill }: { prefill: GetCharityPrefillResponse }) => {
  switch (prefill.flow) {
    case 'PLAN':
      return <PlanLogin prefill={prefill} />;
    case 'REGULAR':
    default:
      return <RegularLogin prefill={prefill} />;
  }
};
