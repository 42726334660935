import { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import { Margin } from '../layout';
import React from 'react';
import { ErrorText } from '../typography';

type CharityStyles = { fontColorHex?: string; bgColorHex?: string };

const StyledTextArea = styled.textarea<{ charityStyles?: CharityStyles }>`
  border: 2px solid ${defaultTheme.colors.grey};
  border-radius: 4px;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  font-family: 'Inter';
  font-size: 0.8rem;
  resize: vertical;

  &:focus {
    border: 2px solid ${({ charityStyles }) => charityStyles?.bgColorHex ?? defaultTheme.colors.mainRed};
    outline: none;
  }

  &::placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }

  &:-ms-input-placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }
`;

const Label = styled.p`
  font-weight: 500;
`;

type ExtraProps = {
  label?: string;
  charityStyles?: CharityStyles;
  onChangeText?: (text: string) => void;
  errorMessage?: string;
};
type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & ExtraProps;

export const TextArea = (props: Props) => {
  return (
    <div>
      {props.label && (
        <>
          <Label>{props.label}</Label>
          <Margin $top="s" />
        </>
      )}

      <StyledTextArea
        rows={5}
        {...props}
        charityStyles={props.charityStyles}
        onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
          props?.onChange?.(ev);
          props?.onChangeText?.(ev.target.value);
        }}
      />
      <Margin $top="s" />
      {props.errorMessage && <ErrorText>{props.errorMessage}</ErrorText>}
    </div>
  );
};
