import { ControlQuestion } from './SignAgreementForm';

/*
 * Some questions we always want present, mandatory control questions are
 * present no matter if custom questions are provided or not.
 */

export const mandatoryControlQuestions: ControlQuestion[] = [
  {
    checked: false,
    label: (
      <>
        Jag godkänner{' '}
        <a href="https://plansverige.org/om-plan/villkor-insamlingspolicy/" target="_blank">
          Plan International Sveriges villkor
        </a>
        . * Du kan läsa mer om vår{' '}
        <a href="https://plansverige.org/om-plan/integritetspolicy/" target="_blank">
          integritetspolicy
        </a>{' '}
        och vår{' '}
        <a href="https://plansverige.org/om-plan/safeguarding-policy/" target="_blank">
          policy för skydd av barn och unga
        </a>
        , som beskriver hur vi hanterar dina personuppgifter samt hur vi skyddar de barn vi arbetar med.
      </>
    ),
    id: 999,
  },
];
