import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useApi } from '../../../traits/hooks/useApi';
import { PlanSignAgreementPage } from './planFlow/PlanSignAgreementPage';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { H2 } from '@expo/ui/src/typography';
import { RegularSignAgreementPage } from './regularFlow/RegularSignAgreementPage';

export const SignAgreementPage = () => {
  const { saleId } = useParams();
  const api = useApi();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const publicKey = queryParams.get('publicKey') ?? '';

  const { data: saleRes, isLoading } = useQuery({
    queryFn: () => api.saleControllerFindPublicSale(saleId ?? '', publicKey),
    queryKey: ['publicSale', saleId],
  });
  const sale = saleRes?.data;

  const chosenCharity = sale?.charity;

  if (isLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  if (!sale) {
    return (
      <MainContentWrapper>
        <H2>Resursen kunde inte laddas in.</H2>
      </MainContentWrapper>
    );
  }

  switch (chosenCharity?.flow) {
    case 'PLAN':
      return <PlanSignAgreementPage sale={sale} />;
    case 'REGULAR':
    default:
      return <RegularSignAgreementPage sale={sale} />;
  }
};
