import Personnummer from 'personnummer';

/**
 * Validates an ssid. The expected format is: YYYYMMDDxxxx.
 * Returns true if valid, false if not.
 *
 * @param {string} ssid The ssid to be validated.
 */

export function validateSsid(ssid: string) {
  if (ssid.includes('-')) return false;

  return Personnummer.valid(ssid);
}
