import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import { ReactElement } from 'react';
import React from 'react';
import { Flex } from '../layout';
import { Padding } from '../layout/Padding';

const CardWrapper = styled(Flex)<{ clickable: boolean; bgColor?: string; maxWidth?: string }>`
  background: ${props => props.bgColor ?? defaultTheme.colors.lightestGrey};
  max-width: ${props => props.maxWidth ?? '100%'};
  border: 2px solid ${defaultTheme.colors.grey};
  border-radius: 4px;

  ${props =>
    props.clickable &&
    `
  cursor: pointer;
  
  &:hover {
    opacity: 0.7;
  }
  `}
`;

type PaddingProp = { padding: keyof (typeof defaultTheme)['spacing'] };

type Props = {
  children: ReactElement | ReactElement[];
  onClick?: () => void;
  bgColor?: string;
  maxWidth?: string;
} & Partial<PaddingProp>;

export const MainCard = ({ children, padding = 'm', onClick, bgColor, maxWidth }: Props) => {
  return (
    <CardWrapper clickable={!!onClick} onClick={onClick} bgColor={bgColor} maxWidth={maxWidth}>
      <Padding $top={padding} $bottom={padding} $left={padding} $right={padding}>
        {children}
      </Padding>
    </CardWrapper>
  );
};
