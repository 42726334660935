import { InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import { Flex, Margin } from '../layout';
import React from 'react';
import { ErrorText, LightP } from '../typography';
import { Icon } from '../icons';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledLabel = styled.label`
  display: inline-block;
  border: 2px dotted ${defaultTheme.colors.grey};
  border-radius: 4px;
  outline: none;
  padding: ${defaultTheme.spacing.xl} ${defaultTheme.spacing.m};
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Label = styled.p`
  font-weight: 500;
`;

type ExtraInputProps = {
  label?: string;
  errorMessage?: string;
};
type Props = InputHTMLAttributes<HTMLInputElement> & ExtraInputProps;

export const UploadFileInput = (props: Props) => {
  const [chosenImg, setChosenImg] = useState<string | null>(null);

  return (
    <Wrapper>
      {props.label && (
        <>
          <Label>{props.label}</Label>
          <Margin $top="s" />
        </>
      )}
      <StyledLabel htmlFor="upload-file">
        <Flex height="100%" width="100%" justify="center" align="center">
          <Icon src="Image" height={40} />
          <Margin $top="s" />
          {chosenImg ? (
            <LightP color={defaultTheme.colors.darkerGrey}>{chosenImg}</LightP>
          ) : (
            <LightP color={defaultTheme.colors.darkerGrey}>Click here to upload a file</LightP>
          )}
        </Flex>
      </StyledLabel>
      <input
        id="upload-file"
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        style={{ zIndex: -1, position: 'absolute', opacity: 0, top: 0, left: 0 }}
        onChange={ev => {
          props?.onChange?.(ev);
          setChosenImg(ev.target.files?.[0].name ?? null);
        }}
      />
      <Margin $top="s" />
      {props.errorMessage && <ErrorText>{props.errorMessage}</ErrorText>}
    </Wrapper>
  );
};
