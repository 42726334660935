import { format } from 'date-fns';
import { z } from 'zod';
import { ControlQuestion } from './SignAgreementForm';

const formSchema = z.object({
  date: z.string().refine(
    date => {
      const now = new Date();
      const expectedDate = format(now, 'yyyy-MM-dd');
      return expectedDate === date;
    },
    { message: 'Datumet är inkorrekt eller i felaktigt format.' },
  ),
});

type ValidationProps = {
  controlQuestions: ControlQuestion[];
  date: string;
};

export function validateControlQuestions(controlQuestionsAndDate: ValidationProps) {
  try {
    const { controlQuestions, date } = controlQuestionsAndDate;
    formSchema.parse({ date });

    if (!controlQuestions.every(({ checked }) => checked)) {
      return { valid: false, errors: ['Vänligen verifiera och kryssa i alla verifieringsfrågor.'] };
    }

    return { valid: true, errors: [] };
  } catch (error) {
    if (error instanceof z.ZodError) {
      const errorMessages = error.errors.map(({ message }) => message);
      return { valid: false, errors: errorMessages };
    } else {
      return { valid: false, errors: ['Something went wrong...'] };
    }
  }
}
