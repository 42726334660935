import { FLAVOR_DEVELOPMENT, FLAVOR_PRODUCTION, FLAVOR_STAGING } from './constants';
import { Flavor } from './types';

const getFlavor = (): Flavor => {
  switch (import.meta.env.VITE_APP_FLAVOR) {
    case 'staging':
      return FLAVOR_STAGING;
    case 'production':
      return FLAVOR_PRODUCTION;
    default:
    case 'development':
      return FLAVOR_DEVELOPMENT;
  }
};

export default getFlavor;
