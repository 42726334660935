import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoadingCircle = styled.div<{ size?: string }>`
  display: inline-block;
  width: ${props => props.size ?? '50px'};
  height: ${props => props.size ?? '50px'};
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-top-color: #000;
  animation: ${rotate} 1s linear infinite;
`;

type LoadingCircleProps = React.HTMLAttributes<HTMLDivElement> & { size?: string };

export const LoadingCircle = React.forwardRef<HTMLDivElement, LoadingCircleProps>((props, ref) => (
  <StyledLoadingCircle ref={ref} {...props} size={props.size} />
));
