import { ReactElement } from 'react';
import styled from 'styled-components';
import React from 'react';
import { defaultTheme } from '../core/theme';

type WrapperProps = {
  $bottom?: string;
  $top?: string;
  $right?: string;
  $left?: string;
  maxWidth?: string;
  fixedWidth?: string;
};

const MainContentWrapperStyles = styled.div<WrapperProps>`
  width: 100%;
  margin: ${props => `
    ${props.$top ?? defaultTheme.spacing.m}
    ${props.$bottom ?? defaultTheme.spacing.m}
    ${props.$right ?? defaultTheme.spacing.m}
    ${props.$left ?? defaultTheme.spacing.m}
  `};
  max-width: ${props => props.maxWidth ?? '550px'};
  ${props => props.fixedWidth && `width: ${props.fixedWidth}`};
  display: flex;
  position: relative;
  flex-direction: column;
`;

const WrapperWithFullWidth = styled.div`
  width: 100%;
  display: flex;
  min-height: 60%;
  justify-content: center;
`;

type Props = { children: ReactElement | ReactElement[] } & WrapperProps;

export const MainContentWrapper = ({ children, $bottom, $top, $right, $left, maxWidth }: Props) => {
  return (
    <WrapperWithFullWidth>
      <MainContentWrapperStyles maxWidth={maxWidth} $top={$top} $bottom={$bottom} $right={$right} $left={$left}>
        {children}
      </MainContentWrapperStyles>
    </WrapperWithFullWidth>
  );
};
