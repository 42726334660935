import { DefaultApiFactory } from '@expo/api-client';
import { useAuth } from '../context/auth';
import config from '../../config';
import Axios from 'axios';

export const useApi = () => {
  const auth = useAuth();

  const axios = Axios.create({});

  axios.interceptors.request.use(
    async config => {
      const token = await auth.getAccessToken().catch(() => '');
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    err => Promise.reject(err),
  );

  axios.interceptors.response.use(
    response => response,
    err => {
      if (err?.response?.status === 401) {
        auth.logout();
      }
      return Promise.reject(err);
    },
  );

  const client = DefaultApiFactory(undefined, config.API_URL, axios);

  return client;
};
