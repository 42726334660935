import styled, { css } from 'styled-components';
import { defaultTheme } from '../core/theme';

type Props = {
  flex?: number;
  flexShrink?: number;
  flexGrow?: number;
  flexDir?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  width?: string;
  height?: string;
  gap?: number;
  backgroundColor?: keyof (typeof defaultTheme)['colors'];
  margin?: string;
};

export const Flex = styled.div<Props>`
  ${props => css`
    display: flex;
    height: ${props.height || 'auto'};
    width: ${props.width || '100%'};
    flex-direction: ${props.flexDir || 'column'};
    align-items: ${props.align ? props.align : 'flex-start'};
    justify-content: ${props.justify ? props.justify : 'flex-start'};
    ${props.gap ?? `gap: ${props.gap || 0}px;`}
    ${props.backgroundColor ?? `background-color: ${props.backgroundColor}; `}
    ${props.margin && `margin: ${props.margin}; `}
  `}
`;
