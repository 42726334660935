import Money from '../assets/money.svg';
import Calendar from '../assets/calendar.svg';
import ArrowRight from '../assets/arrow-right.svg';
import ArrowLeft from '../assets/arrow-left.svg';
import Image from '../assets/image.svg';
import Edit from '../assets/edit.svg';
import Download from '../assets/download.svg';
import Bin from '../assets/bin.svg';

export const IconType = {
  Money,
  Calendar,
  ArrowRight,
  Image,
  Edit,
  Download,
  Bin,
  ArrowLeft,
};

export type IconType = keyof (typeof IconType)[keyof typeof IconType];
