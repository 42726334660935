import styled from 'styled-components';
import { useChosenCharity } from '../context/chosenCharity';
import { defaultTheme } from '@expo/ui/src/core/theme';
import { useChosenPublicCharity } from '../context/chosenPublicCharity';
import { H4, LightP } from '@expo/ui/src/typography';

const Wrapper = styled.div`
  padding: ${defaultTheme.spacing.xl};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  @media (max-width: 580px) {
    padding: ${defaultTheme.spacing.m};
  }
`;

const LeftSection = styled.div`
  width: 45%;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  width: 45%;
  display: flex;
  justify-content: right;

  @media (max-width: 450px) {
    display: none;
  }
`;

const Image = styled.img`
  height: 100%;
  max-width: 100%;
  max-height: 80px;
`;

type Props = {
  name?: string;
  textContent?: string;
  imageUrl?: string;
};

export const Footer = ({ name, textContent, imageUrl }: Props) => {
  const { chosenCharity } = useChosenCharity();
  const { chosenCharity: publicChosenCharity } = useChosenPublicCharity();

  const nameToUse = name ?? chosenCharity?.name ?? publicChosenCharity?.name ?? '';
  const textContentToUse = textContent ?? chosenCharity?.address ?? publicChosenCharity?.address ?? '';
  const imageUrlToUse = imageUrl ?? chosenCharity?.logo.downloadUrl ?? publicChosenCharity?.logo.downloadUrl ?? '';

  return (
    <Wrapper>
      <LeftSection>
        <H4>{nameToUse}</H4>
        <LightP dangerouslySetInnerHTML={{ __html: textContentToUse.replace(/\n/g, '<br>') }} />
      </LeftSection>
      <RightSection>
        <Image src={imageUrlToUse} />
      </RightSection>
    </Wrapper>
  );
};
