import { Status } from '../context/auth';

type AuthProviderState = {
  status: Status;
  isLoading: boolean;
  error: null | string;
};

type LoginAction = {
  type: 'LOGIN';
};
type LoginSuccessAction = {
  type: 'LOGIN_SUCCESS';
};
type LoginFailedAction = {
  type: 'LOGIN_FAILED';
  error: string | null;
};

type LogoutAction = {
  type: 'LOGOUT';
};
type Actions = LoginAction | LoginSuccessAction | LoginFailedAction | LogoutAction;

export default function authContextReducer(state: AuthProviderState, action: Actions) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLoading: true,
        status: Status.Authenticating,
        error: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoading: false,
        status: Status.Authenticated,
        error: null,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        isLoading: false,
        status: Status.NotAuthenticated,
        error: action.error,
      };

    default:
      return state;
  }
}
