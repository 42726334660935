import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import { Margin } from '../layout';
import React from 'react';
import { ErrorText } from '../typography';

type CharityStyles = { fontColorHex?: string; bgColorHex?: string };

const Wrapper = styled.div`
  width: 100%;
`;

const StyledInput = styled.input<{ charityStyles?: CharityStyles }>`
  border: 2px solid ${defaultTheme.colors.grey};
  border-radius: 4px;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  font-family: 'Inter';

  &:focus {
    border: 2px solid ${({ charityStyles }) => charityStyles?.bgColorHex ?? defaultTheme.colors.mainRed};
    outline: none;
  }

  &::placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }

  &:-ms-input-placeholder {
    color: ${defaultTheme.colors.darkerGrey};
  }
`;

const Label = styled.p`
  font-weight: 500;
`;

type ExtraInputProps = {
  label?: string;
  onChangeText?: (text: string) => void;
  charityStyles?: CharityStyles;
  errorMessage?: string;
};
type Props = InputHTMLAttributes<HTMLInputElement> & ExtraInputProps;

export const Input = (props: Props) => {
  return (
    <Wrapper>
      {props.label && (
        <>
          <Label>{props.label}</Label>
          <Margin $top="s" />
        </>
      )}

      <StyledInput
        {...props}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          props?.onChange?.(ev);
          props?.onChangeText?.(ev.target.value);
        }}
      />
      {props.errorMessage && (
        <>
          <Margin $top="s" />
          <ErrorText>{props.errorMessage}</ErrorText>
        </>
      )}
    </Wrapper>
  );
};
