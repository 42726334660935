import styled from 'styled-components';
import React from 'react';
import { defaultTheme } from '@expo/ui/src/core/theme';
import { Flex, Margin } from '@expo/ui/src/layout';
import { H4, LightP } from '@expo/ui/src/typography';

type LeaderBoardItem = {
  displayName: string;
  comparisonValue: number;
};

type Props = {
  title: string;
  items: LeaderBoardItem[];
};

type LeaderBoardStackProps = {
  widthPercentage: number;
  isTop: boolean;
};

const LeaderBoardStack = styled.div<LeaderBoardStackProps>`
  background: ${props => (props.isTop ? defaultTheme.colors.yellow : defaultTheme.colors.lightestGrey)};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  width: ${props => props.widthPercentage}%;
`;

export const LeaderBoard = ({ title, items }: Props) => {
  const sortedItems = [...items].sort((a, b) => b.comparisonValue - a.comparisonValue);

  if (sortedItems.length === 0) {
    return (
      <>
        <H4>{title}</H4>
        <Margin $top="s" />
        <LightP>No data to be shown.</LightP>
      </>
    );
  }

  return (
    <>
      <H4>{title}</H4>
      <Margin $top="s" />
      {sortedItems.map(({ displayName, comparisonValue }, i) => {
        const fraction = comparisonValue / sortedItems[0].comparisonValue;
        const width = fraction * 100;
        const placement = i + 1;

        return (
          <React.Fragment key={`${displayName}-${placement}`}>
            <LeaderBoardStack isTop={placement === 1} widthPercentage={width}>
              <Flex flexDir="row" justify="space-between">
                <LightP>
                  {placement}. {displayName}
                </LightP>
                <LightP>{comparisonValue}</LightP>
              </Flex>
            </LeaderBoardStack>
            <Margin $bottom="s" />
          </React.Fragment>
        );
      })}
    </>
  );
};
