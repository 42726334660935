import React from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';

const StyledSelect = styled.select<{ noBorder: boolean; fullWidth: boolean }>`
  padding: 0.5rem;
  font-family: 'Inter';
  border: ${props => (props.noBorder ? 'none' : `2px solid ${defaultTheme.colors.grey}`)};
  border-radius: 5px;
  max-width: ${props => (props.fullWidth ? '100%' : '22rem')};
  ${props => (props.fullWidth ? 'width: 100%;' : '')}
  background: ${props => (props.noBorder ? 'none' : defaultTheme.colors.white)}};
`;

type Option = {
  label: string;
  value: string;
};

export const Select = ({
  options,
  value,
  onChange,
  noBorder = false,
  fullWidth = false,
}: {
  options: Option[];
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  noBorder?: boolean;
  fullWidth?: boolean;
}) => {
  return (
    <StyledSelect noBorder={noBorder} fullWidth={fullWidth} value={value} onChange={onChange}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};
