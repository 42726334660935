import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';
import { ForwardedRef, forwardRef } from 'react';
import { defaultTheme } from '@expo/ui/src/core/theme';

const Wrapper = styled.div`
  border-radius: 4px;
  border: 2px solid ${defaultTheme.colors.darkerGrey};
  backround-color: ${defaultTheme.colors.lighterGrey};
  height: 240px;
`;

export const Signature = forwardRef((_, ref: ForwardedRef<SignaturePad>) => {
  return (
    <Wrapper>
      <SignaturePad
        ref={ref}
        height={236}
        options={{
          penColor: defaultTheme.colors.black,
          backgroundColor: defaultTheme.colors.lighterGrey,
        }}
      />
    </Wrapper>
  );
});
