import styled, { css } from 'styled-components';
import { defaultTheme } from '../core/theme';

export type Margin = {
  spacing?: keyof (typeof defaultTheme)['spacing'];
  vertical?: keyof (typeof defaultTheme)['spacing'];
  horizontal?: keyof (typeof defaultTheme)['spacing'];
  $top?: keyof (typeof defaultTheme)['spacing'];
  $bottom?: keyof (typeof defaultTheme)['spacing'];
  $left?: keyof (typeof defaultTheme)['spacing'];
  $right?: keyof (typeof defaultTheme)['spacing'];
};

function styledMargin(props: Margin) {
  return css`
    margin-top: ${props.$top
      ? defaultTheme.spacing[props.$top]
      : props.vertical
        ? defaultTheme.spacing[props.vertical]
        : props.spacing
          ? defaultTheme.spacing[props.spacing]
          : 0};
    margin-bottom: ${props.$bottom
      ? defaultTheme.spacing[props.$bottom]
      : props.vertical
        ? defaultTheme.spacing[props.vertical]
        : props.spacing
          ? defaultTheme.spacing[props.spacing]
          : 0};
    margin-left: ${props.$left
      ? defaultTheme.spacing[props.$left]
      : props.horizontal
        ? defaultTheme.spacing[props.horizontal]
        : props.spacing
          ? defaultTheme.spacing[props.spacing]
          : 0};
    margin-right: ${props.$right
      ? defaultTheme.spacing[props.$right]
      : props.horizontal
        ? defaultTheme.spacing[props.horizontal]
        : props.spacing
          ? defaultTheme.spacing[props.spacing]
          : 0};
  `;
}

export const Margin = styled.div<Margin>`
  ${styledMargin}
`;
