import { GetSparData } from '@expo/api-client';
import { useNavigate } from 'react-router-dom';
import kontonummer from 'kontonummer';
import { useChosenCharity } from '../../../../traits/context/chosenCharity';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { formSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useApi } from '../../../../traits/hooks/useApi';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { MainButton, SecondaryButton } from '@expo/ui/src/buttons';
import { Input, TextArea } from '@expo/ui/src/inputs';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { ErrorText, H3 } from '@expo/ui/src/typography';
import { AgreementInfo, InputValues } from '../common/types/AgreementInfo';

type Props = {
  sparData: GetSparData;
  prefills?: Omit<InputValues, 'emailVerification'>;
};

export const CustomerInfo = ({ sparData, prefills }: Props) => {
  const api = useApi();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [accountToValidate, setAccountToValidate] = useState('');
  const [clearingNumberToValidate, setClearingNumberToValidate] = useState('');
  const [postError, setPostError] = useState<null | string>(null);

  const { chosenCharity } = useChosenCharity();

  const assembledBankAccount = `${clearingNumberToValidate}${accountToValidate}`;
  const bankAccountValid = kontonummer.valid(assembledBankAccount);
  const hideBankError = clearingNumberToValidate.length < 3 || accountToValidate.length < 4;

  const onSubmit: SubmitHandler<AgreementInfo> = data => {
    setIsLoading(true);
    api
      .customerControllerUpdateCustomer(data.ssid, {
        email: data.email,
        phone: data.phone,
      })
      .then(() => {
        const formattedAmount = data.amount.replace(/\D/g, '');
        const intAmount = parseInt(formattedAmount);

        api
          .saleControllerUpdateSale(sparData.saleId, {
            amount: intAmount,
            bankAccount: data.bankAccount,
            clearingNumber: data.clearingNumber,
            notes: data.notes,
          })
          .then(() => {
            setIsLoading(false);
            navigate(`sign-agreement/${sparData.saleId}?publicKey=${sparData.publicKey}`);
          });
      })
      .catch(e => {
        setPostError(
          e.message ?? 'Ett okänt fel uppstod. Vänligen testa igen senare, eller pröva att logga in och logga ut.',
        );
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AgreementInfo>({
    resolver: zodResolver(formSchema(chosenCharity)),
    defaultValues: {
      ...sparData,
      email: prefills?.email ?? '',
      phone: prefills?.phone ?? '',
      amount: prefills?.amount ?? '',
      bankAccount: prefills?.bankAccount ?? '',
      notes: prefills?.notes ?? '',
      clearingNumber: prefills?.clearingNumber ?? '',
    },
  });

  const onBack = () => {
    navigate(`/${chosenCharity?.slug}`);
    window.location.reload();
  };

  if (isLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Margin $top="7xl" />
      <H3>Dina uppgifter</H3>
      <Margin $top="l" />
      <Controller
        control={control}
        name="ssid"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Personnummer*"
            placeholder="196606066060"
            maxLength={13}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Förnamn*"
            placeholder="Sven"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Efternamn*"
            placeholder="Svensson"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="address"
        render={({ field }) => (
          <Input
            {...field}
            maxLength={100}
            charityStyles={chosenCharity}
            disabled
            label="Gatuadress*"
            placeholder="Gatan 1"
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="zipCode"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Postnummer*"
            placeholder="12121"
            maxLength={5}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="city"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            disabled
            label="Ort*"
            placeholder="Stockholm"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.email?.message}
            charityStyles={chosenCharity}
            label="E-post*"
            placeholder="sven@mail.com"
          />
        )}
      />

      <Margin $top="l" />
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.phone?.message}
            charityStyles={chosenCharity}
            label="Mobilnummer*"
            placeholder="070-0405942"
            maxLength={25}
          />
        )}
      />

      <Margin $top="l" />
      <Controller
        control={control}
        name="amount"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.amount?.message}
            charityStyles={chosenCharity}
            label="Belopp*"
            placeholder={`...kr`}
            maxLength={100}
          />
        )}
      />

      <Margin $top="l" />
      <Controller
        control={control}
        name="clearingNumber"
        render={({ field }) => (
          <Input
            {...field}
            charityStyles={chosenCharity}
            onChange={ev => {
              setClearingNumberToValidate(ev.target.value);
              field.onChange(ev);
            }}
            errorMessage={errors.clearingNumber?.message}
            label="Clearingnummer*"
            placeholder="1234"
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="bankAccount"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors.bankAccount?.message}
            charityStyles={chosenCharity}
            onChange={ev => {
              setAccountToValidate(ev.target.value);
              field.onChange(ev);
            }}
            label="Bankkonto*"
            placeholder="52740137834"
          />
        )}
      />

      <Margin $top="s" />
      {bankAccountValid || hideBankError ? (
        <></>
      ) : (
        <>
          <ErrorText>
            Bankkontot verkar vara inkorrekt. Om du är säker på att det är korrekt kan du ändå fortsätta till nästa
            steg.
          </ErrorText>
        </>
      )}
      <Margin $top="l" />
      <Controller
        control={control}
        name="notes"
        render={({ field }) => (
          <TextArea {...field} charityStyles={chosenCharity} label="Övrig anteckning" placeholder="Valfri text..." />
        )}
      />
      <Margin $top="s" />
      {errors.notes && <ErrorText>{errors.notes?.message}</ErrorText>}

      <Margin $top="l" />
      {postError && <ErrorText>{postError}</ErrorText>}
      <Margin $top="l" />
      <Margin $top="l" />
      <MainButton charityStyles={chosenCharity} type="submit">
        Gå vidare
      </MainButton>
      <Margin $top="m" />
      <SecondaryButton type="button" onClick={onBack}>
        Gå tillbaka
      </SecondaryButton>
    </form>
  );
};
