import React from 'react';
import { useApi } from '../hooks/useApi';
import { UseQueryResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { GetMyCharityResponse } from '@expo/api-client';

export type ChosenCharityContext = {
  chosenCharity?: GetMyCharityResponse;
  query?: UseQueryResult<AxiosResponse<GetMyCharityResponse>>;
};

export const ChosenCharityContext = React.createContext<ChosenCharityContext>({});

export const ChosenCharityProvider: React.FC<React.PropsWithChildren> = props => {
  const api = useApi();
  const query = useQuery({
    queryFn: async () => {
      return api.charityControllerGetMe();
    },
    queryKey: ['charities', 'me'],
  });

  return (
    <ChosenCharityContext.Provider value={{ chosenCharity: query.data?.data, query }}>
      {props.children}
    </ChosenCharityContext.Provider>
  );
};
