import styled from 'styled-components';
import { defaultTheme } from '../core/theme';

export const SuccessText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 1rem;
  color: ${defaultTheme.colors.green};
`;
