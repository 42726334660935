import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { Header } from './Header';
import AuthProvider from '../auth/provider';
import { PublicChosenCharityProvider } from '../context/publicChosenCharityProvider';
import { PublicCharityCheck } from '../../features/volunteer/publicCharityCheck/PublicCharityCheck';
import { Main } from '@expo/ui/src/layout';

export const PublicLayout: React.FC = () => {
  return (
    <AuthProvider>
      <PublicChosenCharityProvider>
        <PublicCharityCheck>
          <Main>
            <Header />
            <Outlet />
            <Footer />
          </Main>
        </PublicCharityCheck>
      </PublicChosenCharityProvider>
    </AuthProvider>
  );
};
