import styled from 'styled-components';
import { defaultTheme } from '../core/theme';

export const LightP = styled.p`
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.color ?? defaultTheme.colors.black};
`;
