import { GetSparData, ManualEntryDto } from '@expo/api-client';
import { useChosenCharity } from '../../../../../traits/context/chosenCharity';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { formSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useApi } from '../../../../../traits/hooks/useApi';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { MainButton, SecondaryButton } from '@expo/ui/src/buttons';
import { Input } from '@expo/ui/src/inputs';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { ErrorText, H3 } from '@expo/ui/src/typography';
import { useAuth } from '../../../../../traits/context/auth';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

type Props = {
  setSparData: (data: GetSparData) => void;
};

export const ManualSparDataForm = ({ setSparData }: Props) => {
  const api = useApi();
  const mutation = useMutation({
    mutationFn: (data: ManualEntryDto) =>
      api.sparControllerCreate({
        ...data,
      }),
  });
  const auth = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const { chosenCharity } = useChosenCharity();

  const onSubmit: SubmitHandler<ManualEntryDto> = async data => {
    setIsLoading(true);
    try {
      const res = await mutation.mutateAsync(data);
      setSparData(res.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ManualEntryDto>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      volunteerId: parseInt(auth.currentVolunteerId ?? ''),
      ssid: '',
      firstName: '',
      lastName: '',
      address: '',
      zipCode: '',
      city: '',
    },
  });

  const onBack = () => {
    navigate(`/${chosenCharity?.slug}`);
    window.location.reload();
  };

  if (isLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Margin $top="7xl" />
      <H3>Dina uppgifter</H3>
      <Margin $top="l" />
      <Controller
        control={control}
        name="ssid"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.ssid?.message}
            charityStyles={chosenCharity}
            label="Personnummer*"
            placeholder="196606066060"
            maxLength={13}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.firstName?.message}
            charityStyles={chosenCharity}
            label="Förnamn*"
            placeholder="Sven"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.lastName?.message}
            charityStyles={chosenCharity}
            label="Efternamn*"
            placeholder="Svensson"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="address"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.address?.message}
            charityStyles={chosenCharity}
            label="Gatuadress*"
            placeholder="Gatan 1"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="zipCode"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.zipCode?.message}
            charityStyles={chosenCharity}
            label="Postnummer*"
            placeholder="12121"
            maxLength={5}
          />
        )}
      />
      <Margin $top="l" />
      <Controller
        control={control}
        name="city"
        render={({ field }) => (
          <Input
            {...field}
            errorMessage={errors?.city?.message}
            charityStyles={chosenCharity}
            label="Ort*"
            placeholder="Stockholm"
            maxLength={100}
          />
        )}
      />
      <Margin $top="l" />
      {mutation.isError ? (
        <ErrorText>Något gick fel, vänligen försök igen senare eller logga ut och testa igen.</ErrorText>
      ) : (
        <></>
      )}
      <Margin $top="l" />
      <MainButton charityStyles={chosenCharity} type="submit">
        Gå vidare
      </MainButton>
      <Margin $top="m" />
      <SecondaryButton type="button" onClick={onBack}>
        Gå tillbaka
      </SecondaryButton>
    </form>
  );
};
