import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { ErrorText } from '@expo/ui/src/typography';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { useApi } from '../../../traits/hooks/useApi';

export const PlanPaymentRedirect = () => {
  const navigate = useNavigate();
  const api = useApi();
  const { saleId, slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const publicKey = queryParams.get('publicKey') ?? '';
  const sessionId = queryParams.get('sessionId') ?? '';
  const sessionResult = queryParams.get('sessionResult') ?? '';

  const query = useQuery({
    queryFn: () => api.paymentControllerGetAndSetPlanAdyenPaymentStatus(sessionId, sessionResult, publicKey),
  });
  const payment = query.data?.data;

  if (query.isLoading) {
    return (
      <MainContentWrapper>
        <Flex height="100%" width="100%" justify="center" align="center">
          <Margin $top="7xl" />
          <LoadingCircle />
        </Flex>
      </MainContentWrapper>
    );
  }

  if (!payment) {
    return (
      <MainContentWrapper>
        <ErrorText>Something went wrong.</ErrorText>
      </MainContentWrapper>
    );
  }

  switch (payment.status) {
    case 'completed':
    case 'paymentPending':
      navigate(`/${slug}/sign-confirmation?publicKey=${publicKey}`);
      break;
    case 'canceled':
    case 'expired':
      navigate(`/${slug}/sign-agreement/${saleId}?publicKey=${publicKey}&paymentFailed=true`);
      break;
  }

  return (
    <MainContentWrapper>
      <Flex height="100%" width="100%" justify="center" align="center">
        <Margin $top="7xl" />
        <LoadingCircle />
      </Flex>
    </MainContentWrapper>
  );
};
