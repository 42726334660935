import { ReactElement } from 'react';
import { useAuth } from '../../../traits/context/auth';
import { Flex, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';
import { Login } from '../login/Login';

type Props = {
  children: ReactElement | ReactElement[];
};

export const AuthenticationCheck = ({ children }: Props) => {
  const auth = useAuth();

  switch (auth.status) {
    case 'authenticated':
      return <>{children}</>;
    case 'not-authenticated':
      auth.logout(false);
      return <Login />;
    case 'authenticating':
    default:
      return (
        <MainContentWrapper>
          <Flex height="100%" width="100%" justify="center" align="center">
            <Margin $top="7xl" />
            <LoadingCircle />
          </Flex>
        </MainContentWrapper>
      );
  }
};
