import { Flex, Main, MainContentWrapper, Margin } from '@expo/ui/src/layout';
import { ErrorText } from '@expo/ui/src/typography';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../../traits/hooks/useApi';
import { RenderDynamicFlow } from './RenderDynamicFlow';
import { LoadingCircle } from '@expo/ui/src/loadingIndicators';

export const Login = () => {
  const { slug } = useParams();

  const navigate = useNavigate();
  const api = useApi();
  const query = useQuery({
    queryFn: () => api.charityControllerGetCharityPrefill(slug ?? ''),
    queryKey: ['charity', slug, 'prefill'],
  });
  const prefill = query?.data?.data;

  if (query.isError) {
    navigate('/404');
    return <></>;
  }

  if (query.isLoading) {
    return (
      <Main>
        <MainContentWrapper>
          <Flex height="100vh" width="100%" justify="center" align="center">
            <Margin $top="7xl" />
            <LoadingCircle />
          </Flex>
        </MainContentWrapper>
      </Main>
    );
  }

  if (!prefill) {
    return <ErrorText>Expected a chosen charity but got none. Please try logging out and trying again.</ErrorText>;
  }

  return <RenderDynamicFlow prefill={prefill} />;
};
