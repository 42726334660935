import { z } from 'zod';
import { validateSsid } from '../../../../validation/validateSsid';

export const formSchema = z.object({
  volunteerId: z.number(),
  ssid: z
    .string()
    .min(1, { message: 'Personnummer får ej vara tomt.' })
    .min(12, { message: 'Vänligen ange 12-siffrigt personnummer.' })
    .refine(ssid => validateSsid(ssid), { message: 'Personnummer är inte giltigt.' }),
  firstName: z
    .string()
    .min(1, { message: 'Namn får ej vara tomt.' })
    .max(40, { message: 'Förnamn får inte vara över 40 karaktärer.' }),
  lastName: z
    .string()
    .min(1, { message: 'Efternamn får ej vara tomt.' })
    .max(80, { message: 'Efternamn får inte vara över 80 karaktärer.' }),
  address: z.string().min(1, { message: 'Adress får ej vara tomt.' }),
  zipCode: z.string().min(1, { message: 'Postnummer får ej vara tomt.' }),
  city: z.string().min(1, { message: 'Ort får ej vara tomt.' }),
});
