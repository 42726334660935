import { ReactElement } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '../core/theme';
import React from 'react';

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${defaultTheme.colors.white};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const Main = ({ children }: { children: ReactElement[] | ReactElement }) => {
  return <MainWrapper>{children}</MainWrapper>;
};
